import ApplyResult from '../model/applyResult'
import AsynchronousTask from '../model/asynchronousTask'
import { OneTimePayType } from '../model/oneTimePay'
import SalaryType from '../model/salaryType'
import { StagedImportDataEmployee } from '../model/stagedImport'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'
import { StagedImportType } from './staged-import'

export interface StagedPayslipImportResponse {
  done: boolean
  stagedImport: StagedImport
  stagedImportID: string
}

export interface StagedImport {
  companies: Company[]
  errors: StagedImportError[]
  id: string
  periodID: string
  warnings: StagedImportError[]
}

interface Company {
  companyID: string
  dkSpecific: { skatSize: string }
  employees: StagedImportDataEmployee[]
  salaryTypes: SalaryType[]
}

interface StagedImportError {
  field?: number
  message?: string
  row?: number
  type?: string
}

export type ApplyStagedPayslipImportsResponse = {
  done: boolean
  applyResultID?: string
  result: ApplyResult[]
}

export function postStagedPayslipImports(
  importType: StagedImportType,
  companyID: string,
  importFileID: string,
  salaryPeriodID?: string,
  oneTimePayType?: OneTimePayType,
  deleteUnsettled = true
): Promise<RequestResponse<StagedPayslipImportResponse>> {
  const body = {
    type: importType,
    companyID,
    fileID: importFileID,
    salaryPeriodID: salaryPeriodID,
    oneTimePayType: oneTimePayType,
    deleteUnsettled,
  }

  return secureRequest('POST', url('/v2.1/stagedImports'), {
    body,
  })
}

export function applyStagedPayslipImport(
  importDataID: string,
  employeeIds: string[]
): Promise<RequestResponse<ApplyStagedPayslipImportsResponse>> {
  const body = {
    employeeImportIDs: employeeIds,
  }
  return secureRequest('POST', url('/v2.1/stagedImports/' + importDataID), { body })
}

export function getAsyncPayslipImport(id: string): Promise<RequestResponse<StagedImport>> {
  return secureRequest('GET', url(`/v2.1/stagedImports/${id}`))
}

export function getAsyncTaskStatus(id: string): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('GET', url(`/v2/asynchronousTasks/` + id))
}
