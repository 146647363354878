import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import paths from '../../constants/paths'
import { CompanyReducer } from '../../reducers/companies'
import { GlobalMessageReducer } from '../../reducers/globalMessage'
import { t, tx } from '../../utils/translation-utils'

type Props = {
  companies: CompanyReducer
  globalMessage: GlobalMessageReducer
}

export default function GlobalMessage(props: Props): ReactElement | null {
  const content: ReactElement[] = []
  if (props.globalMessage.message) {
    content.push(<div className="global-message">{props.globalMessage.message}</div>)
  }
  if (props.companies.company?.isPayrollLocked) {
    content.push(
      <div className="global-message">
        {tx('header.warning.is_payroll_locked', {
          link: (
            <Link to={'/' + paths.COMPANIES + '/' + props.companies.company.id + '/invoices'}>
              {t('header.warning.is_payroll_locked.link')}
            </Link>
          ),
        })}
      </div>
    )
  }
  return <>{content.map((e) => e)}</>
}
