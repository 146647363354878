import { List } from 'immutable'
import React from 'react'

import PayRoll from '../../model/payRoll'
import { isPayrollDone } from '../../utils/pay-roll-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

type Props = {
  payRolls: List<PayRoll>
  payRoll: PayRoll

  deletePayRoll: (e: React.MouseEvent) => void
}

export default function DeletePayRoll(props: Props) {
  const anyApprovedOpenInPeriod = props.payRolls.some(
    (p) =>
      (p.isApproved || p.isTentative || p.isReviewed) &&
      !isPayrollDone(p) &&
      p.salaryPeriod.id === props.payRoll.salaryPeriod.id &&
      p.id !== props.payRoll.id
  )
  return (
    <Card className="pay-roll-approve">
      <Subtitle>{t('pay_roll.single.delete.title')}</Subtitle>
      <p>{t('pay_roll.single.delete.intro')}</p>
      {anyApprovedOpenInPeriod && (
        <Alert type="warning" message={t('pay_roll.single.delete.warning_about_other_approved')} showIcon />
      )}
      <p>&nbsp;</p>
      <Row>
        <Col span={24}>
          <Button danger type="primary" size="extra-extra-large" block onClick={props.deletePayRoll}>
            {t('pay_roll.single.delete.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
