import { DashboardPayRoll } from '../model/dashboard'
import PayRoll, { UserPayRollStatus } from '../model/payRoll'
import { logWarning } from './log-utils'

export function convertPayRollStatus(payRoll: PayRoll): UserPayRollStatus {
  switch (payRoll.status) {
    case 'Upcoming':
    case 'Scheduled':
      return 'Scheduled'
    case 'InProgress':
      if (payRoll.tasks && payRoll.tasks.length > 0) {
        switch (payRoll.tasks[0].approvalStatus) {
          case 'Approved':
            return 'Approved'
          case 'Tentative':
            return 'Tentative'
          case 'Reviewed':
            return 'Reviewed'
          case 'Rejected':
            return 'Rejected'
          case 'AwaitingExecution':
            return 'Awaiting'
          default:
            if (payRoll.tasks[0].approvalType === 'Automatic') {
              return 'Automatic Approval'
            }
            if (payRoll.tasks[0].approvalStatus === 'No Approval' && payRoll.tasks[0].approvalType === 'No Approval') {
              return 'No Approval'
            }
            break // do nothing
        }
      }
      if (payRoll.isApproved) {
        return 'Approved'
      }
      if (payRoll.isTentative) {
        return 'Tentative'
      }
      if (payRoll.isReviewed) {
        return 'Reviewed'
      }
      if (payRoll.isRejected) {
        return 'Rejected'
      }
      return 'Awaiting Approval'
    case 'Cancelled':
      return 'Cancelled'
    case 'Success':
      return 'Success'
    case 'Failed':
      return 'Failed'
    case 'PartialFailed':
      return 'Partial Failed'
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown pay roll status: `' + payRoll.status + '`')
      }
      return 'Unknown'
  }
}

export function isPayrollDone(payroll: PayRoll): boolean {
  switch (convertPayRollStatus(payroll)) {
    case 'Cancelled':
    case 'Success':
    case 'Failed':
    case 'Partial Failed':
      return true
    default:
      return false
  }
}

export function convertDashboardPayRollStatus(payRoll: DashboardPayRoll): UserPayRollStatus {
  switch (payRoll.approvalState) {
    case 'Pending':
      return 'Awaiting Approval'
    case 'Approved':
      return 'Approved'
    case 'Tentative':
      return 'Tentative'
    case 'Reviewed':
      return 'Reviewed'
    case 'Rejected':
      return 'Rejected'
    case 'AwaitingExecution':
      return 'Awaiting'
    case 'No Approval':
      switch (payRoll.approvalType) {
        case 'Manual':
          return 'Awaiting Approval'
        case 'Automatic':
          return 'Automatic Approval'
        case 'No Approval':
        default:
          return 'No Approval'
      }
    default:
      if (payRoll.approvalType === 'Automatic') {
        return 'Automatic Approval'
      }
      if (payRoll.approvalState === 'No Approval' && payRoll.approvalType === 'No Approval') {
        return 'No Approval'
      }
      if (process.env.NODE_ENV !== 'test') {
        logWarning(
          'Unknown pay roll approval state, type: `' + payRoll.approvalState + ',' + payRoll.approvalType + '`'
        )
      }
      return 'Unknown'
  }
}
