import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import { SalaryDefinitionCreationFields } from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import { DateFormat, Day } from '../../../model/types'
import { ContractReducer } from '../../../reducers/contracts'
import RemunerationType from '../../../types/remuneration-type'
import { formatAPIDate, formatDate, getDate, isTimeAfter } from '../../../utils/date-utils'
import { week } from '../../../utils/day-utils'
import { EmployeePayType, getEmployeePayType, translatePayTypeToRemunerationType } from '../../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { isSalarySupplement } from '../../../utils/salary-type-utils'
import { t } from '../../../utils/translation-utils'
import PayForm from '../../contracts-add/PayForm'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import ContractPayment from '../../form-elements/ContractPayment'
import ContractPeriodWorkHours from '../../form-elements/ContractPeriodWorkHours'
import ContractWorktime, { formWorkCycleToContractWorkCycle } from '../../form-elements/ContractWorktime'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  mutableContract: Contract
  validFrom: Date
  employee: Employee
  contracts: ContractReducer
  company: Company
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
}

type Fields = {
  payType: EmployeePayType
  orgPayType: EmployeePayType
  salaryCycleID: string
  workCycleHours: string[]
  keepWeekOpen: boolean
  weeklyWorkDays: string
  periodWorkHours?: string
  periodWorkHoursForceSimpleMethod: boolean
  remuneration: {
    salary: {
      salaryTypeID: string
      title?: string
      rate?: string
      quantity?: string
    }[]
  }
  workCycle: Record<`workDay${Day}`, boolean>[]
  workCycleAnchorDate?: Date
}

export type PayEditResult = {
  remunerationType: RemunerationType
  orgPayType: EmployeePayType
  salaryCycleID: string
  workCycleHours: number[]
  keepWeekOpen: boolean
  workCycle: Day[][]
  workCycleAnchorDate?: DateFormat
  periodWorkHours?: number
  periodWorkHoursForceSimpleMethod: boolean
  remuneration: {
    salary: SalaryDefinitionCreationFields[]
  }
}

function EmploymentPayEditForm(props: Props & FormComponentProps<Fields, PayEditResult>): ReactElement | null {
  const { getFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      <ContractPayment<Fields>
        decorateField={props.decorateField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setFieldValue={props.setFieldValue}
        company={props.company}
        employee={props.employee}
        salaryCycles={props.salaryCycles}
        salaryTypes={props.salaryTypes}
      />
      {getFieldValue('payType') === 'Commissioned' && (
        <ContractPeriodWorkHours<Fields> decorateField={props.decorateField} />
      )}
      {getFieldValue('payType') === 'Salaried' && (
        <ContractWorktime<Fields>
          company={props.company}
          salaryCycles={props.salaryCycles}
          decorateField={props.decorateField}
          decorateAnyField={props.decorateAnyField}
          getFieldValue={props.getFieldValue}
          setFieldValue={props.setFieldValue}
          getFieldError={props.getFieldError}
        />
      )}
      <PayForm<Fields>
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setFieldValue={props.setFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        disableNewRows={false}
        salaryCycles={props.salaryCycles}
        salaryTypes={props.salaryTypes}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            {isTimeAfter(props.validFrom, getDate())
              ? t('employment_pay.edit.form.valid_from.at_date', { date: formatDate(props.validFrom) })
              : t('employment_pay.edit.form.valid_from.now')}
          </div>
        </Col>
      </Row>
      {props.contracts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, PayEditResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      // Set initial hourly paid value based on whether any hourly rates are set
      payType: getEmployeePayType(props.mutableContract),
      orgPayType: getEmployeePayType(props.mutableContract),
      salaryCycleID: props.mutableContract.salaryCycleID,
      workCycleHours: props.mutableContract.workCycleHours.map((h) => formatInputNumber(h)),
      periodWorkHours: formatInputNumber(props.mutableContract.periodWorkHours),
      periodWorkHoursForceSimpleMethod: props.mutableContract.periodWorkHoursForceSimpleMethod,
      remuneration: {
        salary:
          props.mutableContract.remuneration?.salary.map((row) => ({
            salaryTypeID: row.salaryTypeID,
            title: row.title,
            rate: formatInputNumber(row.rate),
            quantity: row.quantity ? formatInputNumber(row.quantity) : undefined,
          })) || [],
      },
      workCycle: [
        {
          workDayMonday: false,
          workDayTuesday: false,
          workDayWednesday: false,
          workDayThursday: false,
          workDayFriday: false,
          workDaySaturday: false,
          workDaySunday: false,
        },
      ],
      workCycleAnchorDate: props.mutableContract.workCycleAnchorDate
        ? getDate(props.mutableContract.workCycleAnchorDate)
        : undefined,
      keepWeekOpen: true,
      weeklyWorkDays: '0',
    }
    let contractWeeklyWorkDays = props.mutableContract.weeklyWorkDays
    let weeklyWorkDays = 0
    if (props.mutableContract.workCycle) {
      props.mutableContract.workCycle.forEach((w, i) => {
        if (i > 0) {
          fields.workCycle.push({
            workDayMonday: false,
            workDayTuesday: false,
            workDayWednesday: false,
            workDayThursday: false,
            workDayFriday: false,
            workDaySaturday: false,
            workDaySunday: false,
          })
        }
        w.forEach((day) => {
          fields.workCycle[i][`workDay${day}`] = true
          weeklyWorkDays += 1
        })
      })
      weeklyWorkDays = weeklyWorkDays / props.mutableContract.workCycle.length
    } else {
      // fall back to old way
      if (contractWeeklyWorkDays <= 0) {
        contractWeeklyWorkDays = 5 // assume something went wrong, and revert to normal 5
      }
      weeklyWorkDays = contractWeeklyWorkDays
      week.forEach((day, i) => {
        fields.workCycle[0][`workDay${day}`] = i < contractWeeklyWorkDays
      })
    }
    // keep week open if not a regular week
    fields.keepWeekOpen = !(
      fields.workCycle[0].workDayMonday &&
      fields.workCycle[0].workDayTuesday &&
      fields.workCycle[0].workDayWednesday &&
      fields.workCycle[0].workDayThursday &&
      fields.workCycle[0].workDayFriday &&
      !fields.workCycle[0].workDaySaturday &&
      !fields.workCycle[0].workDaySunday
    )
    fields.weeklyWorkDays = formatInputNumber(weeklyWorkDays)
    return fields
  },
  onChange: (key, val, allValues, options, props) => {
    const values = {}
    if (key === 'payType') {
      const type = val as EmployeePayType
      if (type !== allValues.payType) {
        if (type === 'Commissioned') {
          setByPath(values, `remuneration.salary`, [])
        } else {
          // Switch type if hourly paid value changed
          let salaryType = props.salaryTypes.find((type) => type.name === 'Fixed')
          if (type === 'Hourly Paid') {
            salaryType = props.salaryTypes.find((type) => type.name === 'Hourly')
          }
          setByPath(values, `remuneration.salary.0.salaryTypeID`, salaryType?.id)
        }
      }
      setByPath(values, key, type)
    } else if (
      ['weeklyHours', 'weeklyWorkDays'].indexOf(key) !== -1 ||
      key.match(/^remuneration\.salary\.\d+\.rate$/) ||
      key.match(/^workCycleHours.+/)
    ) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else if (key.match(/^remuneration\.salary\.\d+\.quantity$/)) {
      setByPath(
        values,
        key,
        val ? formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })) : ''
      )
    } else {
      setByPath(values, key, val)
    }
    if (key === 'weeklyWorkDays' && parseInputNumber(val as string) !== 5 && !allValues.keepWeekOpen) {
      const num = forceParseInputNumber(val as string)
      if (num >= 1 && num <= 7) {
        // only handle valid ranges
        week.forEach((day, i) => {
          setByPath(values, 'workDay' + day, i < num)
        })
      }
    }
    return values
  },
  onSubmit: (values, props) => ({
    ...values,
    weeklyWorkDays: forceParseInputNumber(values.weeklyWorkDays),
    periodWorkHours:
      !values.periodWorkHoursForceSimpleMethod && values.periodWorkHours
        ? forceParseInputNumber(values.periodWorkHours)
        : undefined,
    workCycle: formWorkCycleToContractWorkCycle(values.workCycle),
    workCycleHours: values.workCycleHours.map((h) => forceParseInputNumber(h)),
    workCycleAnchorDate: values.workCycleAnchorDate ? formatAPIDate(values.workCycleAnchorDate) : undefined,
    remuneration: {
      salary: values.remuneration.salary.map((row) => ({
        ...row,
        title: row.title || undefined,
        rate: forceParseInputNumber(row.rate),
        quantity:
          row.quantity && isSalarySupplement(props.salaryTypes.find((salaryType) => salaryType.id === row.salaryTypeID))
            ? forceParseInputNumber(row.quantity)
            : undefined,
      })),
    },
    remunerationType: translatePayTypeToRemunerationType(values.payType),
  }),
})(EmploymentPayEditForm)
