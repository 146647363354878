import React, { ReactElement, useEffect, useState } from 'react'
import { browserHistory, Link } from 'react-router'

import { addCompanyPricingPackage } from '../../actions/company-pricing-packages'
import { ReactComponent as SalaryLogo } from '../../assets/logo.svg'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RouteProps } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'

import './PricePackageDeal.css'

type Actions = {
  addCompanyPricingPackage: (pricingPackageID: string) => void
}
type Reducers = {
  companyPricingPackages: CompanyPricingPackageReducer
  pricingPackages: PricingPackageReducer
}

type Props = RouteProps

function PricePackageDeal(props: Props & Reducers & Actions): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [upgradeTriggered, setUpgradeTriggered] = useState(false)

  const { companyPricingPackages } = props

  useEffect(() => {
    regularComponentDidUpdate(companyPricingPackages.error, error, setError)
  }, [companyPricingPackages, error])

  useEffect(() => {
    if (
      upgradeTriggered &&
      props.companyPricingPackages.loaded &&
      !props.companyPricingPackages.saving &&
      !props.companyPricingPackages.error &&
      props.companyPricingPackages.companyID
    ) {
      browserHistory.push(`/companies/${props.companyPricingPackages.companyID}/pricing`)
    }
  }, [
    companyPricingPackages,
    props.companyPricingPackages.companyID,
    props.companyPricingPackages.error,
    props.companyPricingPackages.loaded,
    props.companyPricingPackages.saving,
    upgradeTriggered,
  ])

  const upgrade = (e: React.MouseEvent) => {
    e.preventDefault()
    setUpgradeTriggered(true)
    props.addCompanyPricingPackage('7503f08d-f010-5099-4aa0-14ce978756eb')
  }

  return (
    <Card className="package-upgrade premium-upgrade">
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <SalaryLogo fill="var(--sally-link)" />
      <Title>{t('modals.premium_upgrade.title')}</Title>
      <div className="package-upgrade-deal-subtitle">
        Vi værdsætter dig som kunde og derfor vil vi gerne give dig en eksklusiv mulighed. Vi justerer vores priser og
        fra d. 1. maj stiger Basic-pakken til 24 kr. pr. lønseddel. I den forbindelse vil vi gerne tilbyde dig vores
        Premium-pakke til samme pris de første 6 måneder, hvis du opgraderer inden d. 1. maj.
      </div>

      <div className="package-upgrade-deal-price">24 kr.</div>

      <span className="package-upgrade-deal-price-old">39 kr.</span>

      <div className="package-upgrade-deal-price-details">{t('modals.premium_upgrade.price_per')}</div>
      <div className="package-upgrade-deal-price-details-extra">
        24 kr. de første 6 måneder, herefter normalpris af 39 kr. pr. lønseddel.
      </div>
      <div className="package-upgrade-deal-price-details-extra">
        Tilbuddet gælder ikke for brugere, der er eller har været premiumkunder inden for de seneste tre måneder.
      </div>
      <Button onClick={upgrade} size="extra-extra-large" type="primary" className="package-upgrade-deal-button">
        {t('modals.premium_upgrade.button')}
      </Button>
      <div className="package-upgrade-deal-rows">
        <Row>
          <Col span={12}>
            <strong>{t('pricing_package.package.premium.feature_1.title')}</strong>
            {t('pricing_package.package.premium.feature_1.line_1')}
          </Col>
          <Col span={12}>
            <Link
              to="https://help.salary.dk/da/articles/3806651-udlaegshandtering-i-salary"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <strong>{t('pricing_package.package.premium.feature_4.title')}</strong>
            </Link>
            <p>{t('pricing_package.package.premium.feature_4.line_1')}</p>
          </Col>

          <Col span={12}>
            <Link
              to="https://help.salary.dk/da/articles/5316262-medarbejder-skabeloner"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <strong>{t('pricing_package.package.premium.feature_3.title')}</strong>
            </Link>
            <p>{t('pricing_package.package.premium.feature_3.line_1')}</p>
          </Col>

          <Col span={12}>
            <Link
              to="https://help.salary.dk/da/articles/8722683-enhedshandtering"
              target={' _blank'}
              rel="noopener noreferrer"
            >
              <strong>{t('pricing_package.package.premium.feature_2.title')}</strong>
            </Link>
            <p>{t('pricing_package.package.premium.feature_2.line_1')}</p>
          </Col>
        </Row>
      </div>
      {props.companyPricingPackages.saving && <LoadingOverlay />}
    </Card>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    companyPricingPackages: state.companyPricingPackages,
    pricingPackages: state.pricingPackages,
  }),
  {
    addCompanyPricingPackage: addCompanyPricingPackage,
  }
)(PricePackageDeal)
