import {
  applyStagedPayslipImport,
  getAsyncPayslipImport,
  getAsyncTaskStatus,
  postStagedPayslipImports,
  StagedPayslipImportResponse,
} from '../../../api/payslip-import'
import { StagedImportType } from '../../../api/staged-import'
import { usePayslipImportContext } from '../../../context/PayslipImportContext'
import { getActiveCompany } from '../../../utils/cookie-utils'
import { RequestResponse } from '../../../utils/request-utils'
import useUtils from './useUtils'

const useApiHandlers = () => {
  const companyId = getActiveCompany()
  const { extractAsyncStagedData, handleLoading, extractStagedData, handleCreationErrors, goToEmployees } = useUtils()
  const {
    setEmployees,
    setIsStaged,
    setErrors,
    timeoutIdRef,
    selectedPayslipOption,
    fileId,
    setStagedImportId,
    stagedImportId,
    employeeImportIds,
    setLoading,
  } = usePayslipImportContext()

  //Right now there is no explicit handling of the case where the creation task is sent to async, in the case where the responses "done" property is "false", a UI element explaining that it will take a few minutes is shown to the user, handled in handleCreationErrors
  const createEmployeesClicked = async () => {
    if (!stagedImportId) {
      return
    }

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }
    timeoutIdRef.current = null
    handleLoading()

    try {
      const response = await applyStagedPayslipImport(stagedImportId, employeeImportIds)

      if (handleCreationErrors(response)) {
        return
      }

      goToEmployees()
    } catch (error) {
      console.log('Error in creating employees from imported payslips: ', error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetAsyncImportJob = async (id: string) => {
    try {
      const response = await getAsyncPayslipImport(id || '')
      const extractedData = extractAsyncStagedData(response)
      if (extractedData?.errorsExtracted?.length || extractedData?.allEmployeesHaveErrors) {
        throw new Error('error in staging')
      }
      if (extractedData?.employeesExtracted) {
        setEmployees(extractedData?.employeesExtracted)
        setIsStaged(true)
      }
    } catch (error) {
      setErrors({ hasError: true, message: 'payslip_import.step.2.button.staging.error' })
    }
  }

  const pollAsyncJobStatus = async (id: string, interval = 10000, maxAttempts = 10) => {
    let attempts = 0

    //this loop keeps checking the status of the task, every 10 sec, until it reaches the limit or status = success
    //the promise at the end of the loop controls the timeout
    while (attempts < maxAttempts) {
      try {
        const asyncJobResponse = await getAsyncTaskStatus(id)

        switch (asyncJobResponse?.data?.status) {
          case 'Success':
            await handleGetAsyncImportJob(id)
            return

          case 'Failed':
            setErrors({ hasError: true, message: 'payslip_import.step.2.button.staging.error' })
            console.log('❌ Job failed')
            return

          case 'Pending':
            break

          default:
            console.log('Unknown status:', asyncJobResponse?.data?.status)
            break
        }

        attempts++
        await new Promise((resolve) => setTimeout(resolve, interval))
      } catch (error) {
        console.log('Error in poll async job status: ', error)
        setErrors({ hasError: true, message: 'payslip_import.step.2.button.staging.error' })
      }
    }
    if (attempts >= maxAttempts) {
      throw new Error('Max attempts reached in poll async job status')
    }
  }

  const processStagingResponse = async (response: RequestResponse<StagedPayslipImportResponse>) => {
    const responseData = extractStagedData(response)

    setIsStaged(responseData?.stagingDone)
    setStagedImportId(responseData?.stagedImportId)

    //if staging is done go straight to setting the employees state
    if (responseData?.stagingDone) {
      if (responseData?.errorsExtracted.length || responseData?.allEmployeesHaveErrors) {
        setErrors({ hasError: true, message: 'payslip_import.step.2.button.staging.error' })
        return
      }
      setEmployees(responseData?.employeesExtracted)
      return
    }

    //if staging is not done check the response for the id and then start polling the async job
    //the polling method can be given custom interval and maxAttempts if need be, set to the default in the signature atm
    if (responseData?.stagedImportId) {
      await pollAsyncJobStatus(responseData?.stagedImportId)
    } else {
      throw new Error('StagedImport ID is invalid in process staging response')
    }
  }

  const handleStaging = async () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }
    timeoutIdRef.current = null
    handleLoading()
    try {
      const response = await postStagedPayslipImports(
        selectedPayslipOption as StagedImportType,
        companyId || '',
        fileId
      )
      //this is needed because staging could potentially be sent to an async task
      await processStagingResponse(response)
    } catch (error) {
      console.log('Error in handle staging: ', error)
      setErrors({ hasError: true, message: 'payslip_import.step.2.button.staging.error' })
    } finally {
      setLoading(false)
    }
  }

  return {
    handleStaging,
    createEmployeesClicked,
  }
}

export default useApiHandlers
