import { usePayslipImportContext } from '../../../context/PayslipImportContext'
import useApiHandlers from './useApiHandlers'
import useUtils from './useUtils'

export interface PayslipImportErrors {
  hasError: boolean
  message: string
  failedItems?: string[]
  backgroundColor?: string
  color?: string
}

const usePayslipImport = () => {
  const {
    fileName,
    loading,
    step,
    employeeImportIds,
    selectedPayslipOption,
    employees,
    stagedImportId,
    errors,
    timeoutMessage,
    isStaged,
    timeoutIdRef,
    setStep,
    setSelectedPayslipOption,
  } = usePayslipImportContext()
  const { handleFileUpload, handleGoBack, goToEmployees } = useUtils()
  const { handleStaging, createEmployeesClicked } = useApiHandlers()

  return {
    handleFileUpload,
    employees,
    setSelectedPayslipOption,
    stagedImportId,
    createEmployeesClicked,
    loading,
    step,
    setStep,
    selectedPayslipOption,
    fileName,
    handleGoBack,
    employeeImportIds,
    errors,
    timeoutMessage,
    isStaged,
    handleStaging,
    timeoutIdRef,
    goToEmployees,
  }
}

export default usePayslipImport
