import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Agreement, { AgreementType } from '../../model/agreement'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import Subtitle from '../elements/Subtitle'

type Props = {
  agreements: List<Agreement>

  acceptAgreement: (t: AgreementType) => void
}

export default function GDPRTerms(props: Props): ReactElement | null {
  const [scrollAtBottom, setScrollAtBottom] = useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (!ref.current) {
      return
    }
    const scrollContainer = ref.current as HTMLDivElement
    setScrollAtBottom(scrollContainer.scrollTop === scrollContainer.scrollHeight - scrollContainer.clientHeight)
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const scrollContainer = ref.current as HTMLDivElement
    scrollContainer.addEventListener('scroll', handleScroll)
    return () => scrollContainer.removeEventListener('scroll', handleScroll)
  }, [ref])

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()

    props.acceptAgreement('GDPR_1.0')
  }

  return (
    <div className={'terms' + (scrollAtBottom ? ' terms-scrolled' : '')}>
      <div className="terms-text" ref={ref}>
        <p style={{ textAlign: 'center' }}>
          <strong>BILAG 1 TIL Forretningsbetingelser for serviceydelser</strong>
          <br />
          MELLEM
          <br />
          <strong>Salary Aps (databehandler)</strong>
          <br />
          OG
          <br />
          <strong>“Kunden” (dataansvarlig)</strong>
          <br />
          <a href="/databehandleraftale_v7.pdf" target="_blank" rel="noopener noreferrer">
            Se aftalen i PDF
          </a>
        </p>
        <p>&nbsp;</p>
        <Subtitle style={{ textAlign: 'center', fontWeight: 'bold' }}>DATABEHANDLERAFTALE</Subtitle>
        <p>&nbsp;</p>
        <p>
          Denne databehandleraftale er et bilag til den mellem parterne indgåede aftale for serviceydelser og udgør en
          integreret del af Salary’s bestemmelser vedrørende persondata.
        </p>
        <p>
          Der indgås hermed følgende databehandleraftale mellem kunden "Kunden" (den juridiske enhed der benævnes Kunden
          i aftaleforholdet med Salary) og Salary, CVR nr.: 37628409.
        </p>

        <Headline>1. BAGGRUND OG FORMÅL</Headline>
        <p>
          1.1. Den Dataansvarlige (Kunden) og Databehandleren (Salary) har aftale med bilag 1 (”Aftalen”) om levering af
          digitale services i form af løn- håndtering, beregning, udbetaling og indberetning.
        </p>
        <p>
          1.2. I henhold til Aftalen skal Databehandlere behandle personoplysninger på den Dataansvarliges vegne i
          forbindelse med levering af Servicen.
        </p>
        <p>
          1.3. Denne databehandleraftale (herefter ”Databehandleraftalen”) fastsætter betingelser og vilkår for
          Databehandlerens behandling af de personoplysninger (som defineret i Lovgivningen, jf. pkt. 1.4), som Den
          Dataansvarlige i medfør af Aftalen overlader til Databehandleren ved brug af Servicen (herefter
          ”Personoplysningerne”). Med mindre andet udtrykkeligt fremgår af Databehandleraftalen finder Aftalens
          bestemmelser i øvrigt anvendelse.
        </p>
        <p>
          1.4. Databehandleraftalen har til formål at sikre overholdelse af den til enhver tid gældende
          persondatalovgivning, herunder Europa Parlamentets og Rådets forordning (EU) 2016/679 af 27. april 2016 om
          beskyttelse af fysiske personer i forbindelse med behandling af personoplysninger og om fri udveksling af
          sådanne oplysninger og om ophævelse af direktiv 95/46/EF (”Forordningen”), der træder i kraft den 25. maj 2018
          (herefter tilsammen benævnt ”Lovgivningen”).
        </p>

        <Headline>2. TYPER AF PERSONOPLYSNINGER OG DATABEHANDLERENS GENERELLE FORPLIGTELSER</Headline>
        <p>
          2.1. De typer af Personoplysninger og kategorier af registrerede, som Databehandleren skal behandle for den
          Dataansvarlige som led i opfyldelsen af Aftalen er anført i bilag 1A til Databehandleraftalen.
        </p>
        <p>
          2.2. Det er alene den Dataansvarlige, der træffer beslutning om, hvilke Personoplysninger, der behandles af
          Databehandleren, samt til hvilke formål disse personoplysninger må behandles.
        </p>
        <p>
          2.3. Databehandleren behandler alene Personoplysningerne efter dokumenteret instruks fra den Dataansvarlige.
          Se bilag 1A for instruksen
        </p>
        <p>
          2.4. Databehandleren skal behandle Personoplysningerne i overensstemmelse med den til enhver tid gældende
          Lovgivning. Den Dataansvarlige skal sikre, alle Personoplysninger, som den Dataansvarlige overlader til
          Databehandleren sker via funktioner i Servicen.
        </p>
        <p>
          2.5. I tilfælde af at Datatilsynet retter henvendelse vedrørende behandlingen af Personoplysningerne, skal den
          Dataansvarlige og Databehandleren samarbejde om besvarelse af spørgsmål, afgivelse af oplysninger eller
          opfyldelse af eventuelle krav.
        </p>

        <Headline>3. FORTEGNELSER OVER BEHANDLINGSAKTIVITETER</Headline>
        <p>
          3.1. Senest når Forordningen træder i kraft skal Databehandleren føre en fortegnelse over alle kategorier af
          behandlinger, der foretages af Databehandleren på vegne af den Dataansvarlige. Fortegnelsen, der føres
          elektronisk f. eks. som logfiler, skal indeholde:
        </p>
        <p>
          3.1.1. navn på og kontaktoplysninger for Databehandleren, Underdatabehandlere, jf. pkt. 4.1, den
          Dataansvarlige og en eventuel databeskyttelsesrådgiver;
        </p>
        <p>
          3.1.2. kategorierne af de behandlinger, som Databehandleren eller Underdatabehandlere foretager for den
          Dataansvarlige; og
        </p>
        <p>3.1.3. en generel beskrivelse af de tekniske og organisatoriske sikkerhedsforanstaltninger, jf. pkt. 5.</p>
        <p>
          3.2. Fortegnelsen skal foreligge skriftligt, herunder elektronisk. Databehandleren skal efter anmodning fra
          den Dataansvarlige eller Datatilsynet til enhver tid stille fortegnelsen til rådighed for den Dataansvarlige
          og/eller Datatilsynet.
        </p>

        <Headline>4. DATABEHANDLERENS ANVENDELSE AF UNDERLEVERANDØRER</Headline>
        <p>
          4.1. Den Dataansvarlige giver herved samtykke til, at Databehandleren kan anvende underdatabehandlere
          (”Underdatabehandlere”) til opfyldelse af Databehandlerens ydelser i henhold til Aftalen. Det er
          Databehandlerens ansvar, at Underdatabehandlere opfylder sine databeskyttelsesforpligtelser efter
          Lovgivningen.
        </p>
        <p>
          4.2. Ved Aftalens indgåelse anvender Databehandleren Amazon Web Services, Inc. (”AWS”) som Underdatabehandler
          til opbevaring af Personoplysningerne. AWS anvender autoriserede underleverandører til levering af ydelsen til
          Databehandleren. De autoriserede underleverandører, som AWS anvender ved Aftalens indgåelse, fremgår af bilag
          1B sammen med de resterende underleverandører. Såfremt AWS autoriserer nye underleverandører giver
          Databehandleren den Dataansvarlige skriftlig meddelelse herom med mindst 14 dages forudgående varsel. På
          www.salary.dk kan Kunden desuden finde link til AWS’ hjemmeside med liste over AWS’ autoriserede
          underleverandører.
        </p>
        <p>
          4.3. Databehandleren underretter den Dataansvarlige om eventuelle planlagte ændringer i brugen af
          Underdatabehandlere, herunder tilføjelse eller udskiftning af Underdatabehandlere samt anvendelse af nye
          Underdatabehandlere, som ikke er omfattet af pkt. 4.2. Den dataansvarlige har 14 dage til at gøre indsigelse
          mod en ny underdatabehandler. Findes der ikke at kunne imødegås en løsning på indsigelsen mod en ny
          underdatabehandler, har den dataanssvarlige mulighed for at opsige aftalen. j.vf p.kt 4.4
        </p>
        <p>
          4.4. Såfremt den Dataansvarlige ikke kan acceptere ændringer omfattet af pkt. 4.2 eller 4.3, kan den
          Dataansvarlige opsige Databehandleraftalen med 14 dages skriftligt varsel. Uanset bestemmelsen i Aftalens pkt.
          8.3 ophører Aftalen automatisk samtidig med opsigelsen af Databehandleraftalen. Bestemmelserne i Aftalens pkt.
          8 finder i øvrigt uændret anvendelse. Erlagte betalinger fra Kunden refunderes ikke.
        </p>
        <p>
          4.5. Ved ophør af en aftale med en Underdatabehandler, skal Databehandleren give den Dataansvarlige skriftlig
          meddelelse herom. Såfremt Databehandleren ønsker at indgå aftale med en ny Underdatabehandler finder
          bestemmelserne i pkt. 4.1 og 4.3 tilsvarende anvendelse.
        </p>
        <p>
          4.6. Såfremt en Underdatabehandler er etableret i et tredjeland uden for EU/EØS, påhviler det Databehandleren
          at sikre, at Personoplysningerne opbevares indenfor EU/EØS og ikke overføres til det pågældende tredjeland,
          medmindre overførsel er nødvendig til opfyldelse af gældende lovgivningen, der finder anvendelse på
          Databehandlerens eller dennes Underdatabehandlere, eller som følge af krav fra en kompetent offentlig
          myndighed, som er bindende for Databehandleren eller dennes Underdatabehandlere. Databehandleren vil give
          Kunden rimeligt varsel, hvis sådanne krav fremsættes over for Databehandleren eller dennes
          Underdatabehandlere, og vil tilstræbe at give Kunden mulighed for at gøre indsigelse eller anvende relevante
          retsmidler, medmindre Databehandleren eller dennes Underdatabehandlere er forhindret i dette i henhold til
          gældende lovgivning.
        </p>
        <p>Overførslen til tredjelande sker i henhold til EU-U.S. Data Privacy Framework</p>

        <Headline>5. KRAV TIL INFORMATIONSSIKKERHED OG DATABESKYTTELSE</Headline>
        <p>
          5.1. Databehandleren skal træffe de fornødne tekniske og organisatoriske sikkerhedsforanstaltninger mod, at
          Personoplysningerne hændeligt eller ulovligt tilintetgøres, fortabes eller forringes, samt mod at de kommer
          til uvedkommendes kendskab, misbruges eller i øvrigt behandles i strid med Lovgivningen. Sådanne tekniske og
          organisatoriske sikkerhedsforanstaltninger omfatter:
        </p>
        <p>
          5.2. Netværks- sikkerhed, transmission og opbevaring, herunder etablering af login- og adgangskode- procedure
          (to-faktor autentifikation for underleverandører) samt firewalls og antivirus software. Alle dokumenter
          opbevares krypteret og al kommunikation til og fra Databehandlerens server(e) foregår krypteret.
        </p>
        <p>
          5.2.1. Medarbejderforhold, herunder at alene medarbejdere, der er autoriseret hertil, har adgang til
          Personoplysningerne, og at medarbejdere modtager passende uddannelse, fyldestgørende instruktioner i og
          retningslinjer for behandlingen af personoplysningerne, jf. tillige pkt. 7;
        </p>
        <p>
          5.2.2. Fysisk sikkerhed, herunder at adgang til bygninger og systemer, der anvendes i forbindelse med
          databehandlingen er sikret på forsvarlig vis, således at disse ikke er tilgængelige for uvedkommende
          tredjemand.
        </p>
        <p>
          5.3. Databehandleren skal implementere og overholde en sikkerhedspolitik og retningslinjer for behandling af
          Personoplysninger i Databehandlerens organisation, der overholder og opfylder de betingelser og vilkår, som
          til enhver tid fremgår af denne Databehandleraftale og/eller den Dataansvarliges instruks.
        </p>
        <p>
          5.4. Databehandleren foretager løbende evaluering af sikkerhedsniveauet med henblik på at iværksætte
          nødvendige tiltag for at opretholde tilstrækkelig datasikkerhed til enhver tid.
        </p>

        <Headline>6. SIKKERHEDSHÆNDELSER</Headline>
        <p>
          6.1. Databehandleren skal oprette og implementere procedurer for håndtering af brud på persondatasikkerheden,
          jf. Forordningens art. 4 (12) og art. 33. stk. 2.
        </p>
        <p>
          6.2. Databehandleren skal uden unødig forsinkelse efter at være blevet opmærksom herpå skriftligt underrette
          den Dataansvarlige om et brud på persondatasikkerheden, herunder oplysning om, hvem der har behandlet den
          Dataansvarliges oplysninger og hvornår, med henblik på, at den Dataansvarlige har mulighed for at foretage
          politimæssig efterforskning af disse.
        </p>
        <p>
          6.3. Databehandleren skal i tilfælde af et brud på persondataloven, uden unødigt ophold, dog senest 36 timer
          efter at Databehandleren er blevet opmærksom på bruddet på persondatasikkerheden, skriftligt underrette den
          Dataansvarlige herom og som minimum følgende:
        </p>
        <p>
          6.3.1. en beskrivelse af karakteren af bruddet på persondatasikkerheden, herunder – hvis det er muligt -
          kategorierne og det omtrentlige antal berørte registrerede personer samt kategorierne og det omtrentlige antal
          berørte registreringer af personoplysninger;
        </p>
        <p>6.3.2. en beskrivelse af de sandsynlige konsekvenser af bruddet på persondatasikkerheden;</p>
        <p>
          6.3.3. en beskrivelse af de foranstaltninger, som Databehandleren har truffet eller foreslår truffet for at
          håndtere bruddet på persondatasikkerheden, herunder foranstaltninger for at begrænse dets mulige
          skadevirkninger;
        </p>
        <p>
          6.3.4. Navn og kontaktoplysninger på databeskyttelsesrådgiveren, hvis en sådan er udpeget af Databehandleren,
          eller et andet kontaktpunkt, hvor yderligere oplysninger kan indhentes.
        </p>
        <p>
          6.4. Når og for så vidt som det ikke er muligt at give oplysningerne samlet, kan oplysningerne meddeles
          trinvist uden unødig yderligere forsinkelse.
        </p>
        <p>
          6.5. Databehandlerens underretninger til den Dataansvarlige om et brud på persondatasikkerheden i henhold til
          dette pkt. 6 indebærer ikke, at Databehandleren hermed har anerkendt at være i misligholdelse af Aftalen eller
          at være erstatningsansvarlig overfor den Dataansvarlige for det pågældende et brud på persondatasikkerheden.
        </p>

        <Headline>7. HJEMMEARBEJDSPLADSER</Headline>
        <p>
          7.1. Alle arbejdspladser hos Databehandleren er bærebare PC’ere eller lignende. Uanset fysisk placering er
          medarbejdernes adgang til Servicen og Databehandlerens systemer sikret på samme måde
          <br />
          <br />
          Adgang til den virtuelle infrastruktur sker alene gennem krypterede kanaler. Adgang på OS niveau sker via SSH
          og det primære formål er at supportere software udviklingsprocessen.
        </p>
        <p>7.2. Databehandleren skal fastsætte retningslinjer for medarbejdernes behandling af Personoplysninger.</p>

        <Headline>8. DATABEHANDLERENS FORPLIGTELSE TIL AT BISTÅ DEN DATAANSVARLIGE</Headline>
        <p>
          8.1. Databehandleren skal under hensyntagen til behandlingens karakter og de Personoplysninger, der behandles
          af Databehandleren, bistå den Dataansvarlige med at sikre overholdelse af Lovgivningens bestemmelser om de
          registreredes rettigheder vedrørende Personoplysninger. Databehandleren skal herunder ved hjælp af passende
          tekniske og organisatoriske foranstaltninger bistå den Dataansvarlige med håndtering af henvendelser fra en
          registreret, herunder men ikke begrænset til anmodning om indsigt, berigtigelse, blokering eller sletning af
          Personoplysninger. I det omfang den Dataansvarlige selv kan håndtere henvendelser fra en registreret via
          funktioner i Servicen skal den Dataansvarlige benytte sig af disse.
        </p>
        <p>
          8.2. Databehandleren skal endvidere under hensyntagen til behandlingens karakter og de Personoplysninger, der
          behandles af Databehandleren bistå den Dataansvarlige med at overholde øvrige forpligtelser, der påhviler den
          Dataansvarlige efter Lovgivningen, hvor dette er forudsat eller nødvendigt for, at den Dataansvarlige kan
          overholde sine forpligtelser. Databehandleren skal som led heri bistå den Dataansvarlige med at sikre
          overholdelse bl.a. af forpligtelserne i medfør af Forordningens artikel 32-36.
        </p>
        <p>
          8.3. Databehandleren er berettiget til at kræve betaling for ydelser i henhold til dette punkt 8 i
          overensstemmelse med de i Bilag 1C til Aftalen fastsatte priser.
        </p>

        <Headline>9. AUDIT</Headline>
        <p>
          9.1. Databehandleren skal på den Dataansvarliges anmodning give den Dataansvarlige sådanne oplysninger, som er
          nødvendige til at denne kan påse, at Databehandleren og dennes Underdatabehandlere overholder de krav, som er
          fastsat i Databehandleraftalen, herunder at disse har truffet de fornødne tekniske og organisatoriske
          sikkerhedsforanstaltninger og, at foranstaltningerne overholdes.
        </p>
        <p>
          9.2. Databehandleren skal på den Dataansvarliges skriftlige anmodning tilvejebringe dokumentation for, at
          sikkerhedsforanstaltninger er gennemført hos Databehandleren.
        </p>
        <p>
          9.3. Den Dataansvarlige kan via en revisor eller anden betroet part, som er godkendt af den Dataansvarlige og
          Databehandleren, foretage uanmeldt kontrol (indenfor normal arbejdstid) af, at Databehandleren overholder sine
          forpligtelser, herunder kontrol af og eventuel opfølgning på brugeradgange og rettigheder.
        </p>
        <p>
          9.4. Den Dataansvarlige er desuden berettiget til for egen regning at lade en uafhængig tredjepart foretage en
          årlig revision af Databehandlerens behandling af Personoplysninger.
        </p>
        <p>
          9.5. Databehandleren er forpligtet til at give myndigheder, der efter den til enhver tid gældende lovgivning
          har adgang til den Dataansvarliges og Databehandlerens faciliteter, eller repræsentanter, der optræder på
          myndighedens vegne, adgang til Databehandlerens fysiske faciliteter mod behørig legitimation og forudgående
          underskrift af tavshedserklæring.
        </p>

        <Headline>10. DEN DATAANSVARLIGES FORPLIGTELSER OG ANSVAR</Headline>
        <p>
          10.1. Det er den Dataansvarliges ansvar at sikre, at det fornødne grundlag i henhold til Lovgivningen for
          behandling af Personoplysningerne foreligger, og den Dataansvarlige skal ved behandlingen af
          Personoplysningerne overholde og opfylde Lovgivningen.
        </p>
        <p>
          10.2. Den Dataansvarlige skal overholde de til enhver tid gældende sikkerhedsforskrifter, som Databehandleren
          måtte oplyse til den Dataansvarlige vedrørende adgang til og anvendelse af Servicen.
        </p>
        <p>
          10.3. Den Dataansvarlige skal skadesløsholde Databehandleren for sagsanlæg, krav, omkostninger (herunder
          rimelige udgifter til advokatbistand), tab, ansvar, udgifter eller skader, som er en følge af den
          Dataansvarliges manglende overholdelse af Lovgivningen eller de af Databehandleren oplyste
          sikkerhedsforskrifter vedrørende adgang til eller anvendelse af Servicen, eller anden misligholdelse af denne
          Databehandleraftale. Der henvises endvidere til pkt. 14.3
        </p>

        <Headline>11. OMKOSTNINGER OG BETALING</Headline>
        <p>
          11.1. For de ydelser, som Databehandleren leverer til den Dataansvarlige i henhold til denne
          Databehandleraftale betaler den Dataansvarlige de i bilag 1 C til Aftalen fastsatte priser.
        </p>

        <Headline>12. ÆNDRING AF DATABEHANDLERAFTALEN</Headline>
        <p>
          12.1. Hver af Parterne kan til enhver tid med et rimeligt forudgående skriftligt og begrundet varsel kræve
          Databehandleraftalen ændret, hvis ændringen er nødvendig for at overholde den til enhver tid gældende
          Lovgivning.
        </p>
        <p>
          12.2. Databehandleraftalen kan endvidere til enhver tid justeres med et skriftligt varsel på 30 (tredive)
          kalenderdage, såfremt den Dataansvarlige ønsker at justere de i bilag 1 anførte typer af Personoplysninger
          eller kategorier af registrerede.
        </p>
        <p>
          12.3. Hvis der sker en væsentlig ændring eller justering af Databehandleraftalen i medfør af pkt. 12.1 eller
          12.2 til ugunst for Databehandleren, kan Databehandleren opsige Databehandleraftalen med 3 måneders varsel til
          udløbet af en måned, uanset Aftalens pkt. 8.3. Erlagte betalinger fra Kunden refunderes ikke.
        </p>
        <p>
          12.4. Den 25. maj 2016 blev den nye Databeskyttelsesforordning vedtaget. EU’s medlemsstater har derfor indtil
          den 25. maj 2018 til at indrette sin nationale lovgivning til den nye Databeskyttelsesforordning, hvor
          Databeskyttelsesforordningens bødesanktioner tillige træder i kraft. Salary og Kunden er gensidigt forpligtet
          til løbende at sikre overholdelse af Databeskyttelsesforordningen, og om nødvendigt opdatere nærværende
          Databehandleraftale, hvis dette skønnes relevant.
        </p>

        <Headline>13. HÅNDTERING AF DATA EFTER DATABEHANDLERAFTALENS OPHØR</Headline>
        <p>
          13.1. Ved Databehandleraftalens ophør, uanset årsag, finder bestemmelserne i forretningsbetingelserne punkt 11
          anvendelse.
        </p>
        <p>
          <strong>13.2. Sletning og returnering af oplysninger</strong>
        </p>
        <p>
          13.3. Ved ophør af tjenesterne vedrørende behandling af personoplysninger, er databehandleren forpligtet til
          at slette alle personoplysninger, der er blevet behandlet på vegne af den dataansvarlige og bekræfte over for
          den dataansvarlige, at oplysningerne er slettet medmindre EU-retten eller medlemsstaternes nationale ret
          foreskriver opbevaring af personoplysningerne.
        </p>
        <p>
          13.4. Alle lønsedler, indberetninger og medarbejder data kan hentes i hhv. pdf og excel format inden sletning
          til videre brug.
        </p>
        <p>
          13.5. Hvis der efter Databehandleraftalens ophør opstår tvivl om, hvorvidt der er sket sletning af alle
          Personoplysninger, kan den Dataansvarlige anmode om, at Databehandleren for den Dataansvarliges regning
          indhenter en revisorerklæring om, at Personoplysningerne er slettet fra Databehandlerens IT-systemer.
        </p>

        <Headline>14. MISLIGHOLDELSE</Headline>
        <p>
          14.1. Såfremt Databehandleren modtager meddelelse fra den Dataansvarlige, eller Databehandleren bliver bekendt
          med manglende overholdelse af krav ifølge Lovgivningen eller den Dataansvarliges instruks for behandling af
          Personoplysninger, skal Databehandleren uden ugrundet ophold afhjælpe den manglende overholdelse.
        </p>
        <p>
          14.2. I øvrigt finder bestemmelserne i Aftalens pkt. 15 tilsvarende anvendelse ved en Parts misligholdelse af
          Databehandleraftalen.
        </p>
        <p>
          14.3. En Part er forpligtet til at skadesløsholde den anden Part for udgifter og ressourceforbrug ved
          opfyldelse af en Parts forpligtelser over for en tilsynsmyndighed eller den registrerede samt bøder pålagt af
          en tilsynsmyndighed eller en domstol, i det omfang sådanne er forårsaget af den anden Parts misligholdelse.
        </p>

        <Headline>15. DATABEHANDLERAFTALENS IKRÆFTTRÆDEN OG VARIGHED</Headline>
        <p>
          15.1. Databehandleraftalen træder i kraft samtidig med Aftalen og gælder, indtil Aftalen ophører, uanset
          årsag, medmindre andet fremgår af Databehandleraftalens bestemmelser.
        </p>

        <Headline>16. TAVSHEDSERKLÆRING</Headline>
        <p>
          16.1. Databehandleren sikrer, at dennes medarbejdere, der får adgang til oplysninger fra den Dataansvarlige,
          har underskrevet en tavshedserklæring om, at de har tavshedspligt over for uvedkommende med hensyn til deres
          adgang til den Dataansvarliges data. Tavshedspligten er gældende såvel under ansættelsen som efter
          ansættelsens ophør.
        </p>
        <p>
          16.2. Databehandleren skal sikre, at Underdatabehandlere, medarbejdere og andre, der bistår Databehandleren i
          forbindelse med opfyldelse af Aftalen og Databehandleraftalen, er underlagt forpligtelser, som svarer til
          forpligtelserne heri.
        </p>

        <Headline>17. ØVRIGE BESTEMMELSER</Headline>
        <p>
          17.1. I tilfælde af uoverensstemmelse mellem Databehandleraftalen og Aftalen har Databehandleraftalen forrang.
        </p>

        <Headline>18. BEKRÆFTELSE</Headline>
        <p>
          18.1. Databehandleraftalen bekræftes hermed af de to Parter ved godkendelse i Salary Godkender af
          Databehandleraftalen erklærer samtidig at være underskriftsberettigede i medfør af Parternes respektive
          tegnings- og dispositionsregler. Det print- og læsbare bevis for signeringen vil fremgå på virksomhedens
          profil under indstillinger -&gt; virksomhed -&gt; Vilkår
        </p>

        <Headline>BILAG 1A - Instruks, kategorier af registrerede og personoplysninger</Headline>
        <p>
          Dette Bilag udgør den Dataansvarliges instruktion til Databehandleren i forbindelse med Databehandlerens
          databehandling for den Dataansvarlige og er en integreret del af Aftalen.
        </p>
        <p>
          <strong>Formål og karakteren af databehandlingen:</strong>
          <br />
          Salary (databehandler) stiller et lønadministrationssystem til rådighed for Kunden (dataansvarlig) hvor Salary
          gør det muligt for Kunden, at håndtere lønadministration af den Dataansvarliges medarbejdere, eller, hvor den
          Dataansvarlige er en lønadministrationsvirksomhed, der udfører lønadministration for andre virksomheder,
          lønadministration for de virksomheder, som den Dataansvarlige udfører lønadministration for.
          <br />
          <br />
          Kunden tilgår selv systemet via en browser og eget brugernavn og adgangskode, hvorfor Salary ikke egenhændigt
          udfører databehandling i systemet. Systemet vil på foranledning af kunden videregive oplysninger til SKAT,
          ATP, Feriekonto, Nets, pensionsselskaber, Danmarks Statistik m.fl. Men kun med det formål at leve op til
          lovgivningen omkring beregning, indberetning og udbetaling af løn.
          <br />
          <br />
          Udover lønadministrationssystemet tilbyder Databehandleren - eller vil tilbyde - visse tilvalgsmoduler, som
          kunden mod yderligere betaling kan tilvælge til sin aftale, eksempelvis et HR administrationsmodul for
          administration af kundens medarbejdere.
        </p>
        <p>
          <strong>Kategorier af registrerede personer</strong>
          <br />
          Potentielle kunder:
          <br />
          1. Tilmeldte til nyhedsbrev
          <br />
          2. Besøgende på hjemmeside
          <br />
          Kunder:
          <br />
          1. Kundens oplysninger
          <br />
          2. Kundens medarbejdere
          <br />
          Partnere:
          <br />
          1. Lønadministratorer
          <br />
          <br />
          <strong>Kategorier af personoplysninger</strong>
          <br />
          <br />
          <strong>Potentielle kunder</strong>
          <br />
          <strong>1. Tilmeldte til nyhedsbrev</strong>
          <br />
          - Navn
          <br />
          - Email-Adresse
          <br />
          - Virksomhed
          <br />
          - Særlige interesseområder
          <br />
          <strong>2. Besøgende på hjemmeside:</strong>
          <br />
          - IP-adresse på den besøgende
          <br />
          - Hvilke sider den besøgende har kigget på og hvornår – den besøgendes ”elektroniske spor”
          <br />
          - Den besøgendes browser
          <br />
          <br />
          <strong>Kunder</strong>
          <br />
          <strong>1. Kundens oplysninger</strong>
          <br />
          - Navn
          <br />
          - Adresse
          <br />
          - CVR-nummer
          <br />
          - Kontaktoplysninger
          <br />
          - Kontaktpersoner hos den Dataansvarlige, herunder disses kontaktoplysninger, så som telefon, email, titel,
          afdeling, adresse
          <br />
          - Bankkonti til brug for Applikationen
          <br />
          - Bankkonti eller betalingskort til brug for betalinger til Salary
          <br />
          <br />
          <strong>2. Kundens medarbejdere</strong>
          <br />
          - Navn
          <br />
          - Adresse
          <br />
          - CPR-nummer
          <br />
          - Kontaktoplysninger, herunder telefon, email, titel, afdeling, adresse · Navn og kontaktoplysninger for
          medarbejdernes pårørende
          <br />
          - Løn og lønsedler <br />
          - Skat og skattefradrag
          <br />
          - Arbejdsmarkedsbidrag
          <br />
          - ATP bidrag
          <br />
          - Sociale bidrag
          <br />
          - Pensionsbidrag
          <br />- Fravær (f.eks. grundet sygdom, barsel, militærtjeneste, borgerligt ombud, periodiske ansættelser,
          o.s.v.) · <br />
          - Opgørelse af feriedage
          <br />
          - Tillæg (f.eks. medarbejderens rejseudlæg, kørselsgodtgørelse, m.v.)
          <br />
          - Fradrag (herunder den Dataansvarliges udlæg, fagforeningskontingent, A-kasse bidrag, efterlønsbidrag, m.v.)
          <br />
          - Bankkonti til brug for lønudbetaling
          <br />
          - Uploadede dokumenter (f.eks. ansættelseskontrakt, MUS-samtaler, straffeattest, o.s.v.)
          <br />
          <strong>Partnere:</strong>
          <br />
          <strong>1. Lønadministratorer:</strong>
          <br />
          - Navn
          <br />
          - Adresse
          <br />
          - CVR-nummer
          <br />
          - Kontaktoplysninger
          <br />
          - Kontaktpersoner hos Administrator, herunder disses kontaktoplysninger, så som telefon, email, titel,
          afdeling, adresse
          <br />
          <br />
          <strong>Særlige kategorier af personoplysninger:</strong>
          <br />
          Salary behandler og opbevarer kun særlige oplysninger, som den Dataansvarlige selv eller dennes medarbejdere
          indlæser/uploader til Salary, og som er relevante for den Dataansvarliges lønadministration for den enkelte
          medarbejder.
          <br />
          <br />
          Disse kan omfatte:
          <br />
          - Feriepenge: Afhængigt af hvilken feriepengemodtager den registrerede persons feriepenge skal afregnes til,
          kan det udledes hvilken fagforeningstilknytning personen har.
          <br />
          - Fravær (f.eks. grundet sygdom, barsel, militærtjeneste, borgerligt ombud, periodiske ansættelser, o.s.v.)
          <br />- Fradrag (herunder den Dataansvarliges udlæg, fagforeningskontingent, A-kasse bidrag, efterlønsbidrag,
          m.v.)
        </p>

        <Headline>
          BILAG 1B - Oversigt over aktuelle underdatabehandlere, omfatter ved ikrafttrædelsen af denne Aftale:
        </Headline>
        <table style={{ border: '1px solid #ccc' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ccc' }}>Navn</th>
              <th style={{ border: '1px solid #ccc' }}>Sted/land</th>
              <th style={{ border: '1px solid #ccc' }}>Bistår Databehandleren med</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid #ccc' }}>
                Amazon Web Services
                <br />
                Ørestads Boulevard 73
                <br />
                2300 København S
              </td>

              <td style={{ border: '1px solid #ccc' }}>Danmark</td>
              <td style={{ border: '1px solid #ccc' }}>
                Hosting
                <br />
                Link til subcontractor access
              </td>
            </tr>
            <tr>
              <td style={{ border: '1px solid #ccc' }}>
                Intercom
                <br />
                3rd Floor, Stephens Ct.
                <br />
                18-21 St. Stephen’s Green
                <br />
                Dublin 2
              </td>
              <td style={{ border: '1px solid #ccc' }}>Irland</td>
              <td style={{ border: '1px solid #ccc' }}>CRM, support platform og Emails</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid #ccc' }}>
                we-serve-you
                <br />
                Valhalvej 4<br />
                8230 Åbyhøj
                <br />
                ApS
                <br />
                CVR: 27913962
              </td>
              <td style={{ border: '1px solid #ccc' }}>Danmark</td>
              <td style={{ border: '1px solid #ccc' }}>SMS udsendelse</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid #ccc' }}>
                Mit.dk
                <br />
                Netcompany A/S, CVR-nr.: 14814833
                <br />
                Grønningen 17, 1.
                <br />
                1270 København K
              </td>
              <td style={{ border: '1px solid #ccc' }}>Danmark</td>
              <td style={{ border: '1px solid #ccc' }}>Elektronisk post</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid #ccc' }}>
                e-Boks A/S
                <br />
                Hans Bekkevolds Allé 7<br />
                2900 Hellerup
                <br />
                CVR-nr.: 25674154
              </td>
              <td style={{ border: '1px solid #ccc' }}>Danmark</td>
              <td style={{ border: '1px solid #ccc' }}>Elektronisk post</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid #ccc' }}>
                Mastercard Payment Services Denmark
                <br />
                Lautrupbjerg 10
                <br />
                Postboks 500
                <br />
                2750 Ballerup
                <br />
                (CVR: 40695869)
              </td>
              <td style={{ border: '1px solid #ccc' }}>Danmark</td>
              <td style={{ border: '1px solid #ccc' }}>Lønoverførsler post</td>
            </tr>
          </tbody>
        </table>

        <Headline>Bilag 1C - Priser for assistance til dataansvarlig i forbindelse med forespørgsler og </Headline>
        <p>
          Såfremt databehandler ikke kan assistere den dataansvarlige ud fra eksisterende services og simple
          forespørgsler faktureres der 800 kr. plus moms. pr time
        </p>
      </div>

      <div className="terms-footer">
        <Button onClick={handleSubmit} type="secondary" id="button-accept-company-terms">
          Accepter betingelser
        </Button>
      </div>
    </div>
  )
}
