import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Employee from '../../model/employee'
import { ExpenseCategoryBaseFields, ExpenseCategoryUpdateFields } from '../../model/expenseCategory'
import { CompanyAccountPlanReducer } from '../../reducers/companyAccountPlans'
import { EmployeeReducer } from '../../reducers/employees'
import { ExpenseCategoryReducer } from '../../reducers/expenseCategories'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import LoadingOverlay from '../widgets/LoadingOverlay'
import EmployeeNondisburseableAccountEdit from './EmployeeNondisburseableAccountEdit'
import ExpenseCategoryEdit from './ExpenseCategoryEdit'

type Props = {
  expenseCategories: ExpenseCategoryReducer
  companyAccountPlans: CompanyAccountPlanReducer
  employees: EmployeeReducer

  addAlert: addAlertSignature
  getExpenseCategories: () => void
  addExpenseCategory: (expenseCategory: ExpenseCategoryBaseFields) => void
  updateExpenseCategory: (expenseCategory: ExpenseCategoryUpdateFields) => void
  deleteExpenseCategory: (expenseCategoryID: string) => void
  updateEmployee: (employee: Employee) => void
  getCompanyAccountPlans: () => void
}

export default function ExpenseCategoriesTab(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editingCategory, setEditingCategory] = useState<string | boolean>(false)
  const [editingEmployee, setEditingEmployee] = useState<string | boolean>(false)

  const { expenseCategories, getExpenseCategories, companyAccountPlans, getCompanyAccountPlans } = props

  useEffectOnce(() => {
    if (!expenseCategories.loading && !expenseCategories.loaded) {
      getExpenseCategories()
    }
    if (!companyAccountPlans.loading && !companyAccountPlans.loaded) {
      getCompanyAccountPlans()
    }
  })

  const setEditCategoryVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditingCategory(id)
  }
  const setEditEmployeeVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditingEmployee(id)
  }

  const { employees } = props

  const previousExpenseCategories = usePrevious(expenseCategories)
  const previousEmployees = usePrevious(employees)

  useEffect(() => {
    // Check for save callback
    if (previousExpenseCategories && previousExpenseCategories.saving && !expenseCategories.saving) {
      // Check for no error occurred
      if (!expenseCategories.error) {
        // Close edit modal
        setEditCategoryVisibility(false)
      }
    }
    // Check for save callback
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      // Check for no error occurred
      if (!employees.error) {
        // Close edit modal
        setEditEmployeeVisibility(false)
      }
    }
  }, [previousExpenseCategories, previousEmployees, expenseCategories, employees])

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteExpenseCategory(id)
      }
    }
  }

  type ExpenseCategoryRow = {
    key: string
    id: string
    accountName?: string
  }

  const categoryColumns = [
    { title: t('expense_category.tab.category.table.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('expense_category.tab.category.table.header.order'), dataIndex: 'order', key: 'order' },
    {
      title: t('expense_category.tab.category.table.header.account'),
      dataIndex: '',
      key: 'x1',
      render: (expenseCategory: ExpenseCategoryRow) => {
        if (!expenseCategory.accountName) {
          return <em>{t('expense_category.tab.category.table.no_account')}</em>
        }
        return expenseCategory.accountName
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x2',
      className: 'company-table-actions',
      render: (expenseCategory: ExpenseCategoryRow) => {
        if (deleting.indexOf(expenseCategory.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('expense_category.tab.category.table.actions.edit')}>
              <span onClick={() => setEditCategoryVisibility(expenseCategory.id)} style={{ cursor: 'pointer' }}>
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
            <Tooltip title={t('expense_category.tab.category.table.actions.delete')}>
              <span onClick={remove(expenseCategory.id)} style={{ cursor: 'pointer' }}>
                <Icon type="xSign" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  type EmployeeRow = {
    key: string
    id: string
    name: string
    accountName?: string
  }

  const employeeColumns = [
    { title: t('expense_category.tab.employee.table.header.name'), dataIndex: 'name', key: 'name' },
    {
      title: t('expense_category.tab.employee.table.header.account'),
      dataIndex: '',
      key: 'x1',
      render: (employee: EmployeeRow) => {
        if (!employee.accountName) {
          return <em>{t('expense_category.tab.employee.table.standard_account')}</em>
        }
        return employee.accountName
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x2',
      className: 'company-table-actions',
      render: (employee: EmployeeRow) => {
        return (
          <div>
            <Tooltip title={t('expense_category.tab.employee.table.actions.edit')}>
              <span onClick={() => setEditEmployeeVisibility(employee.id)} style={{ cursor: 'pointer' }}>
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getExpenseCategoryRows = (): ExpenseCategoryRow[] => {
    return props.expenseCategories.expenseCategories
      .filter((expenseCategory) => expenseCategory.active)
      .map((expenseCategory) => {
        let accountName
        if (expenseCategory.accountingAccountID) {
          const accountingAccount = props.companyAccountPlans.accounts.find(
            (account) => account.id === expenseCategory.accountingAccountID
          )
          if (accountingAccount) {
            accountName = accountingAccount.accountNumber
            if (accountingAccount.name) {
              accountName += ' ' + accountingAccount.name
            }
          }
        }
        return {
          key: expenseCategory.id,
          id: expenseCategory.id,
          name: expenseCategory.name,
          accountName,
          order: expenseCategory.order,
        }
      })
      .toArray()
  }
  const getEmployees = (): EmployeeRow[] => {
    return props.employees.employees
      .map((employee) => {
        let accountName
        if (employee.nondisburseableAccountingAccountID) {
          const accountingAccount = props.companyAccountPlans.accounts.find(
            (account) => account.id === employee.nondisburseableAccountingAccountID
          )
          if (accountingAccount) {
            accountName = accountingAccount.accountNumber
            if (accountingAccount.name) {
              accountName += ' ' + accountingAccount.name
            }
          }
        }
        return {
          key: employee.id,
          id: employee.id,
          name: employee.name || employee.email || '-',
          accountName,
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .toArray()
  }

  if (!expenseCategories.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <div>
      <Card>
        <TitleMenu>
          <Link to={'/' + paths.REIMBURSEMENT_VOUCHERS}>
            <Button>{t('general.back.button')}</Button>
          </Link>
          <Button type="primary" onClick={() => setEditCategoryVisibility(true)} prefixIcon="plusCircle">
            {t('expense_category.tab.header.add_category')}
          </Button>
        </TitleMenu>
        <Title>{t('expense_category.tab.category.title')}</Title>

        <Table columns={categoryColumns} dataSource={getExpenseCategoryRows()} pagination={false} />

        <Modal
          key={modalKey}
          visible={editingCategory !== false}
          onOk={() => setEditCategoryVisibility(false)}
          onCancel={() => setEditCategoryVisibility(false)}
          width={582}
          footer={null}
        >
          <ExpenseCategoryEdit
            visible={editingCategory !== false}
            expenseCategoryID={typeof editingCategory === 'string' ? editingCategory : undefined}
            expenseCategories={props.expenseCategories}
            accounts={props.companyAccountPlans.accounts}
            addExpenseCategory={props.addExpenseCategory}
            updateExpenseCategory={props.updateExpenseCategory}
          />
        </Modal>
      </Card>
      {props.companyAccountPlans.accounts.size > 0 && (
        <Card>
          <Title>{t('expense_category.tab.employee.title')}</Title>

          <p>{t('expense_category.tab.employee.description')}</p>

          <Table columns={employeeColumns} dataSource={getEmployees()} pagination={false} />
          <Modal
            key={modalKey}
            visible={editingEmployee !== false}
            onOk={() => setEditEmployeeVisibility(false)}
            onCancel={() => setEditEmployeeVisibility(false)}
            width={582}
            footer={null}
          >
            <EmployeeNondisburseableAccountEdit
              visible={editingEmployee !== false}
              employeeID={typeof editingEmployee === 'string' ? editingEmployee : ''}
              employees={props.employees}
              accounts={props.companyAccountPlans.accounts}
              updateEmployee={props.updateEmployee}
            />
          </Modal>
        </Card>
      )}
    </div>
  )
}
