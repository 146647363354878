import { useEffect, useState } from 'react'

import { DateFormat } from '../../model/types'
import { formatDate } from '../../utils/date-utils'
import { t } from '../../utils/translation-utils'
import Select from '../elements/select'
import { PayRollRow } from './PayRolls'

export interface payrollByDate {
  year: number
  payrollList: PayRollRow[]
}

type Props = {
  initialPayrolls: PayRollRow[]
  setFilteredPreviousPayrolls: React.Dispatch<React.SetStateAction<PayRollRow[]>>
  setSelectValue: React.Dispatch<React.SetStateAction<string>>
}

const YearlyPayrollSelect = ({ initialPayrolls, setFilteredPreviousPayrolls, setSelectValue }: Props) => {
  const [payrollsMap, setPayrollsMap] = useState<payrollByDate[]>([])

  const filterByDispositionDate = (dispositionDate: string) => {
    let filteredPayrolls: PayRollRow[]

    // Check if dispositionDate is a 4-digit year (length is 4)
    if (dispositionDate.length === 4) {
      filteredPayrolls = initialPayrolls.filter(
        (item) => new Date(item.dispositionDate).getFullYear().toString() === dispositionDate
      )
    } else {
      filteredPayrolls = initialPayrolls.filter((item) => item.dispositionDate === dispositionDate)
    }

    setFilteredPreviousPayrolls(filteredPayrolls)
  }

  const handleSelected = (date: string) => {
    setSelectValue(date)
    filterByDispositionDate(date)
  }

  const groupByYear = (data: PayRollRow[]) => {
    const yearMap = new Map<number, PayRollRow[]>()

    data.forEach((item) => {
      const year = new Date(item.dispositionDate).getFullYear()
      if (!yearMap.has(year)) yearMap.set(year, [])
      yearMap.get(year)!.push(item)
    })

    const arrFromMap = Array.from(yearMap, ([year, payrollList]) => ({ year, payrollList }))

    setPayrollsMap(arrFromMap)
  }

  const filterByUniqueDate = (data: PayRollRow[]) => {
    const uniqueDateMap = new Map<string, DateFormat>()

    for (const payrollItem of data) {
      if (!uniqueDateMap.has(payrollItem.dispositionDate)) {
        uniqueDateMap.set(payrollItem.dispositionDate, payrollItem.dispositionDate)
      }
    }

    return Array.from(uniqueDateMap.values())
  }

  useEffect(() => {
    groupByYear(initialPayrolls)
  }, [])

  return (
    <div className="payrolls-select-container">
      <label className="payroll-select-label">{t('payroll.filter.label')}</label>
      <Select
        size="medium"
        placeholder={t('payroll.filter.selected.value.all')}
        className="payrolls-select"
        onSelect={(value) => handleSelected(value.toString())}
      >
        <Select.Option value="">{t('payroll.filter.selected.value.all')}</Select.Option>

        {payrollsMap.map(({ year, payrollList }) => (
          <Select.OptGroup key={year} label={year.toString()}>
            <Select.Option key={year} value={year.toString()}>
              {t('payroll.filter.selected.value.all.year')}
            </Select.Option>

            {filterByUniqueDate(payrollList).map((payrollDate) => (
              <Select.Option key={payrollDate.toString()} value={payrollDate.toString()}>
                {formatDate(payrollDate)}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
    </div>
  )
}

export default YearlyPayrollSelect
