// Credit: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
import Language from '../types/language'
import { getCurrentLanguage } from './language-utils'
import { t } from './translation-utils'

type NumberFormats = {
  language: Language
  decimal: string
  thousand: string
  currencySuffix: string
}

let numberFormats: NumberFormats | undefined
function init() {
  if (!numberFormats || numberFormats.language !== getCurrentLanguage()) {
    numberFormats = {
      language: getCurrentLanguage(),
      decimal: t('number.decimal_separator'),
      thousand: t('number.thousand_separator'),
      currencySuffix: t('number.currency_suffix'),
    }
  }
}

// this to ensure it does not have to go through t() every time
function sep(item: 'dec' | 'tho'): string {
  init()
  switch (item) {
    case 'dec':
      return numberFormats!.decimal
    case 'tho':
      return numberFormats!.thousand
  }
  return ''
}

function round(value: number, exp: number): number {
  value = value || 0
  exp = exp === undefined ? -2 : -Math.abs(exp)
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value)
  }
  value = +value
  exp = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  let s = value.toString().split('e')
  value = Math.round(+(s[0] + 'e' + (s[1] ? +s[1] - exp : -exp)))
  // Shift back
  s = value.toString().split('e')
  return +(s[0] + 'e' + (s[1] ? +s[1] + exp : exp))
}

/**
 * Formats a number, so it is ready for an input field
 *
 * @param num The number to be formatted
 * @param maxDecimals The maximum number of decimals to be displayed (default 6)
 */
export function formatInputNumber(num?: number | string, maxDecimals = 6, includeThousandSeparator = true): string {
  if (!num) {
    return '0'
  }
  if (typeof num === 'number' && isNaN(num)) {
    return num.toString()
  }
  let str = ''
  if (typeof num === 'number') {
    num = num || 0
    if (num % 1 !== 0) {
      num = round(num, maxDecimals)
    }
    str = num.toString()
  } else {
    str = num
  }
  const parts = str.replace(/,/g, '').replace(/\./g, ',').split(',')
  if (isNaN(parseInt(parts[0])) || (parts[1] && isNaN(parseInt(parts[1])))) {
    // if either part is invalid, return what it was
    return str
  }
  if (includeThousandSeparator) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep('tho'))
  }
  return parts.join(sep('dec'))
}

type ParseNumberOptions = {
  trim: boolean
  permitNoTrim: boolean
}

/**
 * Parses a form input number.  If the input cannot become a valid number, it will simply return the input as is.
 * If the `trim` option is set, it will be more aggressive in turning the string into a number, such as trimming
 * trailing '.'
 * @param str If `number`, it will simply be returned.
 * @param options
 */
export function parseInputNumber(str?: string | number, options?: Partial<ParseNumberOptions>): number | string {
  options = options ?? ({ trim: false, permitNoTrim: false } as ParseNumberOptions)
  if (typeof str === 'number') {
    return str
  }
  const orgStr = str as string // keep the original str, if the number we create is no longer a number
  str = str || ''
  if (options.permitNoTrim && str.match(/[^0-9,.-]/g)) {
    // if we are required to do any trimming to parse the numbers (and we explicit disallow it), bail
    return orgStr
  }
  str = str.replace(/[^0-9,.-]/g, '')
  str = str.replace(/^0+/, '') //remove prefixed 0s
  let num = str.replace(new RegExp('\\' + sep('tho'), 'g'), '').replace(new RegExp('\\' + sep('dec'), 'g'), '.')
  if (num) {
    if (num === '.') {
      if (options.trim) {
        // assume it is 0 point, so assume it is 0
        num = '0'
      } else {
        // special case, handle when 0 point something
        num = '0.'
      }
    }
    if (!options.trim && (num.match(/\.$/) || num.match(/\..*0$/))) {
      return isNaN(parseFloat(num)) ? orgStr : num
    }
    return isNaN(parseFloat(num)) ? orgStr : parseFloat(num)
  }
  return 0
}

/**
 * Ensures that the result of parseInputNumber is either a number or undefined (i.e. never string).
 * If parseInputNumber cannot return a valid number, or returns 0, it will return undefined.
 * If you do not wish for 0 to be considered undefined, use forceParseInputNumber instead.
 * This should only really be used for fields, where 0 and nothing should be considered the same.
 * @param str
 */
export function softForceParseInputNumber(str?: string | number): number | undefined {
  const result = parseInputNumber(str, { trim: true })
  if (typeof result === 'number') {
    if (result === 0) {
      // special handling, 0 is interpreted as undefined
      return undefined
    }
    return result
  }
  return !isNaN(parseFloat(result)) ? parseFloat(result) : undefined
}

/**
 * Ensures the result of parseInputNumber is always a number.
 * If parseInputNumber cannot return a valid number, it will return 0.
 * @param str
 */
export function forceParseInputNumber(str?: string | number): number {
  return softForceParseInputNumber(str) || 0
}

/**
 * Formats the number in a human displayable fashion
 *
 * Examples:
 * * formatNumber(2.25) -> 2
 * * formatNumber(2.25, 2) -> 2,25
 * * formatNumber(2.25, 4) -> 2,2500
 * * formatNumber(2.25, 4, false) -> 2,25
 *
 * @param num The number to display
 * @param decimals How many decimals to display (default 0)
 * @param fillWithZeros If decimals are provided, different from 0; if true (default): force the number of decimals; if false: limit to the number of decimals
 */
export function formatNumber(num?: number, decimals?: number, fillWithZeros = true): string {
  if (num === undefined) {
    // convert num to 0, so it may get its decimals attached
    num = 0
  }
  decimals = decimals || 0
  let value = formatInputNumber(round(num, decimals)).toString()
  // Fill with zeros, if necessary
  if (fillWithZeros && decimals > 0) {
    if (value.indexOf(sep('dec')) === -1) {
      value += sep('dec')
    }
    const diff = decimals - value.split(sep('dec'))[1].length
    for (let i = 0; i < diff; i++) {
      value += '0'
    }
  }
  return value
}

/**
 * A helper function for displaying a number with maximum 2 decimals
 *
 * @param num The number to display
 * @param maxDecimals Maximum number of decimals (default 2)
 */
export function formatDisplayNumber(num?: number, maxDecimals = 2): string {
  return formatNumber(num, maxDecimals, false)
}

export function formatDiffNumber(num: number, decimals: number): string {
  return (num >= 0 ? '+' : '') + formatNumber(num, decimals)
}

export function formatHours(num?: number, decimals = 2): string {
  return formatNumber(num, decimals) + ' ' + t('time_registrations.table.header.hours')
}

export function formatCurrency(num?: number, decimals = 0): string {
  init()
  return formatNumber(num, decimals) + ' ' + numberFormats!.currencySuffix
}

export function formatDiffCurrency(num: number, decimals: number): string {
  return (num >= 0 ? '+' : '') + formatCurrency(num, decimals)
}

export function addLeadingZeros(num: number | string, pad = 2): string {
  if (typeof num === 'string') {
    if (num.length < pad) {
      return '0'.repeat(pad - num.length) + num
    } else {
      return num
    }
  }
  let output = ''
  for (let i = pad - 1; i > 0; i--) {
    if (num < Math.pow(10, i)) {
      output += '0'
    }
  }
  output += num
  return output
}

export function formatMinutesAsTime(num: number): string {
  const hours = Math.floor(num / 60)
  return `${addLeadingZeros(hours)}:${addLeadingZeros(num % 60)}`
}

export function parseTimeAsMinutes(time: string): number {
  const [hours, minutes] = time.split(':')
  let parsedHours = 0
  let parsedMinutes = 0
  if (hours !== undefined) {
    parsedHours = parseInt(hours, 10) * 60
  }
  if (minutes !== undefined) {
    parsedMinutes = parseInt(minutes, 10)
  }
  return parsedHours + parsedMinutes
}

export function formatInputAsMinutes(input: string): string {
  const formatted = input.replace(/[ :,.]/g, '').replace(/^(\d{1,2})(\d{2})/, '$1:$2')
  return formatted.match(/^\d{1}:\d{2}$/) ? '0' + formatted : formatted
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isNumeric(n: any): boolean {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

/**
 * This function basically removes thousand separators and converts
 * decimal separators to '.'
 * @param s
 */
export function prepareCalculatorInput(s: string): string {
  return s.replace(new RegExp('\\' + sep('tho'), 'g'), '').replace(new RegExp('\\' + sep('dec'), 'g'), '.')
}

/**
 * This function converts . and , to decimal pointer for the language
 * @param s
 */
export function formatInputDecimalSeparator(s: string): string {
  return s.replace(/,/g, sep('dec')).replace(/\./g, sep('dec'))
}

/**
 * Returns the ordinal suffix for the number, but not the number itself
 * @param n
 */
export function formatOrdinalSuffix(n: number): string {
  switch (getCurrentLanguage()) {
    case Language.DANISH:
      return '.'
    case Language.ENGLISH: {
      // from https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
      const j = n % 10,
        k = n % 100
      if (j == 1 && k != 11) {
        return 'st'
      }
      if (j == 2 && k != 12) {
        return 'nd'
      }
      if (j == 3 && k != 13) {
        return 'rd'
      }
      return 'th'
    }
  }
}

/**
 * Return a number with its ordinal suffix (essentially formatNumber and formatOrdinalSuffix combined)
 * @param n
 */
export function formatOrdinalNumber(n: number): string {
  return `${formatNumber(n, 0)}${formatOrdinalSuffix(n)}`
}
