import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import {
  getCSVReport,
  getExcelReport,
  getGroupExcelReport,
  getPDFReport,
  getStatisticsReport,
  ReportCreation,
} from '../../api/reports'
import AsynchronousTask, { AsynchronousTaskStatus } from '../../model/asynchronousTask'
import { ReportType } from '../../model/report'
import { DateFormat } from '../../model/types'
import { formatError } from '../../utils/error-utils'
import { RequestError, RequestResponse, secureUrl } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import { IconType } from '../elements/icon'

export type RegularReportFields = {
  readonly type: 'Regular'
  companyID: string
  report: ReportType
  from: DateFormat
  to: DateFormat
  payRollID?: string
  voucherID?: string
  reimbursementVoucherID?: string
}

export type GroupReportFields = {
  readonly type: 'Group'
  companyGroupID: string
  report: ReportType
  from: DateFormat
  to: DateFormat
  companies: string[]
}

export type DSTReportFields = {
  readonly type: 'DST'
  companyID: string
  period: string
  year: number
}

type BasicReportType = 'Excel' | 'CSV' | 'DST' | 'Group Excel' | 'PDF'

type Props = {
  state: number
  asynchronousTasks?: List<AsynchronousTask>
  type: BasicReportType
  icon?: IconType | null
  text?: string
  buttonType?: 'primary' | 'secondary'
  className?: string

  addAlert: addAlertSignature
  getFields: () => RegularReportFields | DSTReportFields | GroupReportFields | undefined
  isValid?: () => boolean
  onStateChange?: (_ready: boolean) => void
}

export default function ReportButton(props: Props): ReactElement | null {
  const [taskID, setTaskID] = useState<string>()
  const [fileID, setFileID] = useState<string>()
  const [taskStatus, setTaskStatus] = useState<AsynchronousTaskStatus>()
  const [loading, setLoading] = useState(false)
  const [buttonLocked, setButtonLocked] = useState(false)

  const { asynchronousTasks, onStateChange } = props

  useEffect(() => {
    if (!asynchronousTasks) {
      return
    }
    if (taskID) {
      const task = asynchronousTasks.find((task) => task.id === taskID)
      if (task) {
        if (task.status !== taskStatus) {
          setTaskStatus(task.status)
          if (task.status === 'Success') {
            if (task.report) {
              setLoading(false)
              setFileID(task.report.fileID)
              if (onStateChange) {
                onStateChange(true)
              }
            }
          }
        }
      }
    }
  }, [taskID, asynchronousTasks, taskStatus, setTaskStatus, setFileID, setLoading, onStateChange])

  const reset = () => {
    setTaskID(undefined)
    setFileID(undefined)
    setTaskStatus(undefined)
    setLoading(false)
  }

  const { state } = props
  const previousState = usePrevious(state)

  useEffect(() => {
    if (state !== previousState) {
      reset()
    }
  }, [state, previousState])

  const isWaiting = () => {
    if (loading) {
      return true
    }
    if (!taskID) {
      return false
    }
    return taskStatus !== 'Success' && taskStatus !== 'Failed'
  }

  const isDone = () => {
    if (!taskID) {
      return false
    }
    return taskStatus === 'Success'
  }

  const reportResult = (res: RequestResponse<ReportCreation>) => {
    setButtonLocked(false)
    if (res.data.done) {
      setLoading(false)
      if (onStateChange) {
        onStateChange(true)
      }
      window.open(secureUrl('/v2/reportFile/' + res.data.fileID), '_blank')
    } else {
      setTaskID(res.data.taskID)
    }
  }

  const reportError = (e: Error) => {
    if (e instanceof RequestError) {
      props.addAlert('error', formatError(e))
    }
  }

  const doNothing = () => {
    setButtonLocked(false)
    setLoading(false)
  }

  const newReport = () => {
    reset()
    setLoading(true)
    setButtonLocked(true)
    if (onStateChange) {
      onStateChange(false)
    }

    switch (props.type) {
      case 'Excel': {
        const fields = props.getFields()
        if (!fields || fields.type !== 'Regular') {
          doNothing()
          return
        }
        getExcelReport(
          fields.companyID,
          fields.report,
          fields.from,
          fields.to,
          fields.payRollID,
          fields.voucherID,
          fields.reimbursementVoucherID
        )
          .then(reportResult)
          .catch(reportError)
        break
      }
      case 'PDF': {
        const fields = props.getFields()
        if (!fields || fields.type !== 'Regular') {
          doNothing()
          return
        }
        getPDFReport(fields.companyID, fields.report, fields.from, fields.to, fields.payRollID)
          .then(reportResult)
          .catch(reportError)
        break
      }
      case 'CSV': {
        const fields = props.getFields()
        if (!fields || fields.type !== 'Regular') {
          doNothing()
          return
        }
        getCSVReport(
          fields.companyID,
          fields.report,
          fields.from,
          fields.to,
          fields.payRollID,
          fields.voucherID,
          fields.reimbursementVoucherID
        )
          .then(reportResult)
          .catch(reportError)
        break
      }
      case 'DST': {
        const fields = props.getFields()
        if (!fields || fields.type !== 'DST') {
          doNothing()
          return
        }
        getStatisticsReport(fields.companyID, fields.period, fields.year).then(reportResult).catch(reportError)
        break
      }
      case 'Group Excel': {
        const fields = props.getFields()
        if (!fields || fields.type !== 'Group') {
          doNothing()
          return
        }
        getGroupExcelReport(fields.companyGroupID, fields.companies, fields.report, fields.from, fields.to)
          .then(reportResult)
          .catch(reportError)
        break
      }
    }
  }

  const click = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!taskID) {
      newReport()
    } else if (isDone() && fileID) {
      window.open(secureUrl('/v2/reportFile/' + fileID), '_blank')
    }
  }

  const isValid = () => {
    if (!props.isValid) {
      return true
    }

    return props.isValid()
  }

  const { icon = 'arrowDownIntoTray' } = props

  return (
    <Button
      onClick={click}
      type={props.buttonType}
      className={(props.className ?? '') + ' gtm-download-report-button'}
      loading={isWaiting()}
      disabled={!isValid() || buttonLocked}
      prefixIcon={icon ?? undefined}
      size="extra-large"
    >
      {!isWaiting() && (
        <>
          {props.text ? (
            props.text
          ) : (
            <>{t('report_button.download', { type: props.type === 'CSV' ? 'CSV' : 'Excel' })}</>
          )}
          {isDone() ? t('report_button.done') : ''}
        </>
      )}
      {isWaiting() && <>{t('report_button.waiting')}</>}
    </Button>
  )
}
