import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import Invoice from '../../model/invoice'
import StripeConfiguration, { StripeConfigurationSetup } from '../../model/stripeConfiguration'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t, tx } from '../../utils/translation-utils'
import StripeIntegrationModal from '../companies-single/stripe/StripeIntegrationModal'
import Button from '../elements/button'
import Card from '../elements/card'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Modal from '../elements/modal'
import Subtitle from '../elements/Subtitle'
import DumbLink from '../widgets/DumbLink'

type Props = {
  approvers: List<CompanyUser>
  hasDeadline: boolean
  neededApprovers: number
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  invoices: List<Invoice>
  company: Company

  closeModal: () => void
  addAlert: addAlertSignature
  addStripeConfiguration: () => Promise<StripeConfigurationSetup | void>
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
}

type Fields = {
  users: { [key: string]: boolean }
  approvalCode?: string
}

export type ApproveResult = {
  userIDs: string[]
  approvalCode?: string
}

function ApprovePayRoll(props: Props & FormComponentProps<Fields, ApproveResult>): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showStripe, setShowStripe] = useState(false)

  const setStripeVisibility = (showStripe: boolean) => {
    // Increment modalKey to create a new component
    setModalKey(modalKey + 1)
    setShowStripe(showStripe)
  }

  const hasPaymentIntegration = () => {
    return props.companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => (v.paymentIntegrationType === 'Stripe' || v.paymentIntegrationType === 'NETS') && v.status !== 'Expired'
    )
  }

  const invoiceAmount = () => {
    const invoice = props.invoices.first()
    return invoice ? invoice.total || 0 : 0
  }

  if ((!hasPaymentIntegration() || props.company.isPayrollLocked) && invoiceAmount() > 0) {
    return (
      <Card className="pay-roll-approve">
        {props.company.isPayrollLocked && (
          <>
            <Subtitle>{t('pay_roll.single.approve.payroll_locked.title')}</Subtitle>
            <p>{t('pay_roll.single.approve.payroll_locked.intro.line_1')}</p>
            <p>
              {tx('pay_roll.single.approve.payroll_locked.intro.line_2', {
                link: (
                  <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/invoices'}>
                    {t('pay_roll.single.approve.payroll_locked.intro.line_2.link')}
                  </Link>
                ),
              })}
            </p>
          </>
        )}
        {!props.company.isPayrollLocked && !hasPaymentIntegration() && (
          <>
            <Subtitle>{t('pay_roll.single.approve.no_payment_integration.title')}</Subtitle>
            <p>{t('pay_roll.single.approve.no_payment_integration.intro.line_1')}</p>
            <p>{t('pay_roll.single.approve.no_payment_integration.intro.line_2')}</p>
          </>
        )}
        <DumbLink
          style={{ marginTop: '15px', float: 'right' }}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            setStripeVisibility(true)
          }}
        >
          <Button type="primary" className="gtm-add-stripe-integration">
            {t('pay_roll.single.approve.create_stripe_integration_button')}
          </Button>
        </DumbLink>

        <Modal
          key={modalKey}
          visible={showStripe}
          onOk={() => setStripeVisibility(false)}
          onCancel={() => setStripeVisibility(false)}
          width={376}
          footer={null}
        >
          <StripeIntegrationModal
            visible={showStripe}
            hasIntegration={false}
            company={props.company}
            closeModal={() => {
              setStripeVisibility(false)
              props.closeModal()
            }}
            addAlert={props.addAlert}
            addStripeConfiguration={props.addStripeConfiguration}
            updateStripeConfiguration={props.updateStripeConfiguration}
          />
        </Modal>
      </Card>
    )
  }

  const { decorateField, decorateAnyField } = props
  const approvers = props.approvers

  return (
    <Card className="pay-roll-approve">
      <Subtitle>
        {props.neededApprovers === 1
          ? t('pay_roll.single.approve.title.finally')
          : t('pay_roll.single.approve.title.partial')}
      </Subtitle>
      <p>
        {t('pay_roll.single.approve.intro')}{' '}
        {!props.hasDeadline && <>{t('pay_roll.single.approve.intro.no_deadline')}</>}
      </p>
      {props.neededApprovers > 1 && (
        <p>{t('pay_roll.single.approve.partial_approval', { count: props.neededApprovers - 1 })}</p>
      )}
      {props.neededApprovers > 1 && approvers.size > 0 && (
        <Row>
          <Col span={24}>
            <p>{t('pay_roll.single.approve.select_other_approvers')}</p>
            {approvers.map((approver) => {
              return decorateAnyField('users.' + approver.userID, { valueOnChecked: true })(
                <Checkbox id={'users.' + approver.userID} key={'users.' + approver.userID}>
                  {approver.name}
                </Checkbox>
              )
            })}
          </Col>
        </Row>
      )}
      {props.company.askForPayrollApprovalCode && (
        <Row>
          <Col span={24}>
            {decorateField('approvalCode', {
              placeholder: t('pay_roll.single.approve.approval_code'),
            })(<Input />)}
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" block>
            {t('pay_roll.single.approve.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, ApproveResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      users: {},
    }
    // only pre-select approvers if there is only one
    const selected = props.approvers.size < 2
    props.approvers.forEach((approver) => {
      fields.users[approver.userID] = selected
    })
    return fields
  },
  onSubmit: (values) => {
    const userIDs: string[] = Object.keys(values.users).filter((key) => values.users[key])
    return {
      userIDs,
      approvalCode: values.approvalCode,
    }
  },
})(ApprovePayRoll)
