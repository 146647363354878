import React, { ReactElement } from 'react'

import { AgreementReducer } from '../../reducers/agreements'
import { formatDateTime } from '../../utils/date-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'

type Props = {
  visible: boolean
  agreements: AgreementReducer
}

export default function TermsModal(props: Props): ReactElement | null {
  const agreement = props.agreements.agreements.find((agreement) => agreement.type === 'GDPR_1.0')
  return (
    <Card>
      <Title>{t('data_provider_terms.title')}</Title>
      {agreement && (
        <p>{tx('data_provider_terms.signed_at', { time: <strong>{formatDateTime(agreement.signedAt)}</strong> })}</p>
      )}
      {!agreement && <p>{t('data_provider_terms.not_signed')}</p>}
      <p>&nbsp;</p>
      <Row>
        <Col span={24}>
          <a href="/databehandleraftale_v7.pdf" target="_blank" rel="noopener noreferrer">
            <Button size="extra-extra-large" type="primary" block>
              {t('data_provider_terms.download')}
            </Button>
          </a>
        </Col>
      </Row>
    </Card>
  )
}
