import React from 'react'

interface ImportDetailSectionProps {
  sectionKey?: string
  title: string
  openSection?: { [key: string]: boolean }
  toggleFold?: (section: string) => void
  children: React.ReactNode
}

const ImportDetailSection: React.FC<ImportDetailSectionProps> = ({ title, children }) => {
  return (
    <div className="employeeImportDetails_section" style={{ marginBottom: '2vh' }}>
      <div
        style={{
          gap: '10px',
          marginBottom: '1vh',
        }}
      >
        <p style={{ fontWeight: 'bold' }}>{title}</p>
      </div>
      <>{children}</>
    </div>
  )
}

export default ImportDetailSection
