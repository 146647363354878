import React, { useEffect, useState } from 'react'

import { StagedImportDataEmployee } from '../../../model/stagedImport'
import { t } from '../../../utils/translation-utils'
import Input from '../../elements/input/Input'

export type OpenSectionState = { [key: string]: boolean }

const useEmployeeImportDetails = (employeeData: StagedImportDataEmployee) => {
  const noValuePresent = t('payslip_import.employees.table.row.info.field.empty')
  const [openSection, setOpenSection] = useState<OpenSectionState>({
    all: false,
  })
  const [employee, setEmployee] = useState<StagedImportDataEmployee | null>(null)

  useEffect(() => {
    setEmployee(employeeData)
  }, [employeeData])

  const toggleFold = (section: string) => {
    setOpenSection((prev) => ({ ...prev, [section]: !prev[section] }))
  }

  const renderInputField = (label: string, field: string, value: string) => {
    return (
      <div
        key={label}
        className="employeeImportDetail"
        style={{
          marginBottom: '0.5vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <label className="employeeImportDetail_label" htmlFor={field}>
          {label}
        </label>
        <Input
          disabled
          className="employeeImportDetail_input"
          type="text"
          value={value}
          style={{ backgroundColor: 'white', fontSize: '11px' }}
        />
      </div>
    )
  }

  const translate = (fieldValue: string | undefined) => {
    switch (fieldValue) {
      case 'DenmarkVacationAccrual':
        return t('payslip_import.employees.table.row.info.field.denmark.vacation.accrual')
      case 'DenmarkVacationFund':
        return t('payslip_import.employees.table.row.info.field.denmark.vacation.fund')
      case 'Normaltimer':
        return t('payslip_import.employees.table.row.info.field.normal.hours')
      case 'Månedsløn':
        return t('payslip_import.employees.table.row.info.field.monthly.salary')
      case 'Ikke ferieberettiget løn 1':
        return t('payslip_import.employees.table.row.info.field.ceo.salary')
      default:
        return fieldValue
    }
  }

  return {
    employee,
    renderInputField,
    toggleFold,
    openSection,
    noValuePresent,
    translate,
  }
}

export default useEmployeeImportDetails
