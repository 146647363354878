import Fuse from 'fuse.js'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { fetchIntercomArticles } from '../../api/intercom'
import paths from '../../constants/paths'
import { EmployeeReducer } from '../../reducers/employees'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import { Row } from '../elements/grid'
import Input from '../elements/input/Input'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import GlobalSearchCard from './GlobalSearchCard'
import * as searchData from './searchData.json'
import * as searchSites from './searchSites.json'

import './GlobalSearch.css'

type Props = {
  id: number
  employees: EmployeeReducer
  employeesLimit: number
  articlesLimit: number
  characterLimit?: number

  removeModal: (id: number) => void
}

export type GlobalSearchRow = {
  id: string
  title: string
  description?: string
  url: string
  target: string
  onClick?: () => void
}

export type SearchDataRow = {
  title: string
  description?: string
  url: string
  searchTerms: string
}

export type SearchSitesRow = {
  title: string
  description?: string
  url: string
  searchTerms: string
}

export default function GlobalSearch(props: Props): ReactElement | null {
  const [inputSearchTerm, setInputSearchTerm] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [employeeResults, setEmployeeResults] = useState<GlobalSearchRow[]>()
  const [articleResults, setArticleResults] = useState<GlobalSearchRow[]>()
  const [searchDataResults, setSearchDataResults] = useState<GlobalSearchRow[]>()
  const [searchSitesResults, setSearchSitesResults] = useState<GlobalSearchRow[]>()
  const [error, setError] = useState<Error | null>(null)
  const characterLimit = props.characterLimit || 50
  // Determines what searched element is selected.
  // When set to -1 there has been no arrow pressed yet.
  const [selectedPosition, setSelectedPosition] = useState(-1)
  const [fuseEmployees] = useState(() => {
    // Employees fuse options
    const fuseEmployeeOptions = {
      keys: ['name', 'address', 'email', 'nationalID', 'activeEmployment.employeeNumber'],
      threshold: 0.3,
      includeScore: true,
      includeMatches: true,
      limit: 3,
    }
    return new Fuse(props.employees.employees.toArray(), fuseEmployeeOptions)
  })

  // Data from searchData.json
  const [fuseSearchData] = useState(() => {
    const fuseDataSettings = {
      keys: ['title', 'description', 'searchTerms', 'url'],
      threshold: 0.3,
      includeScore: true,
      includeMatches: true,
      limit: 3,
    }
    return new Fuse<SearchDataRow>((searchData as any).default ?? searchData, fuseDataSettings)
  })

  //Data from searchSites.json
  const [fuseSearchSites] = useState(() => {
    const fuseSearchSitesSettings = {
      keys: ['title', 'description', 'searchTerms', 'url'],
      threshold: 0.3,
      includeScore: true,
      includeMatches: true,
      limit: 3,
    }
    return new Fuse<SearchSitesRow>((searchSites as any).default ?? searchSites, fuseSearchSitesSettings)
  })

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setSearchTerm(inputSearchTerm)
    }, 250)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchTerm])

  useEffect(() => {
    if (!inputRef.current) {
      return
    }
    inputRef.current.focus()
  }, [])

  const eventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const employeeNumber = employeeResults?.length ?? 0
    const searchDataNumber = searchDataResults?.length ?? 0
    const searchSitesNumber = searchSitesResults?.length ?? 0
    const articleNumber = articleResults?.length ?? 0

    switch (e.key) {
      case 'ArrowDown':
        setSelectedPosition((prev) => {
          if (prev + 1 === employeeNumber + searchDataNumber + searchSitesNumber + articleNumber) {
            return prev
          }
          return prev + 1
        })
        return

      case 'ArrowUp':
        setSelectedPosition((prev) => {
          if (prev === 0) {
            return prev
          }
          return prev - 1
        })
        return

      case 'Enter':
        if (employeeNumber > 0 && selectedPosition < employeeNumber && employeeResults) {
          const employee = employeeResults[selectedPosition]
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id)
          props.removeModal(props.id)
        } else if (
          searchDataNumber > 0 &&
          selectedPosition >= employeeNumber &&
          selectedPosition < employeeNumber + searchDataNumber &&
          searchDataResults
        ) {
          const data = searchDataResults[selectedPosition - employeeNumber]
          jsBrowserHistory.push(data.url)
          props.removeModal(props.id)
        } else if (
          searchSitesNumber > 0 &&
          selectedPosition >= employeeNumber + searchDataNumber &&
          selectedPosition < employeeNumber + searchDataNumber + searchSitesNumber &&
          searchSitesResults
        ) {
          const data = searchSitesResults[selectedPosition - employeeNumber - searchDataNumber]
          jsBrowserHistory.push(data.url)
          props.removeModal(props.id)
        } else if (
          articleNumber > 0 &&
          selectedPosition >= employeeNumber + searchDataNumber + searchSitesNumber &&
          articleResults
        ) {
          const article = articleResults[selectedPosition - employeeNumber - searchDataNumber - searchSitesNumber]
          window.open(article.url, '_blank', 'noopener,noreferrer')
        }
        break
    }
  }

  const loadEmployees = useCallback(
    (term: string) => {
      const matches = fuseEmployees.search(term, { limit: 3 })
      const employeeRows: GlobalSearchRow[] = []
      matches.map((e) => {
        let description = '-'
        if (e.item.address && e.item.postalCode && e.item.city) {
          description = `${e.item.address}, ${e.item.postalCode} ${e.item.city}`
        }
        employeeRows.push({
          title: e.item.name || e.item.email || '-',
          id: e.item.id,
          url: paths.EMPLOYEES + '/' + e.item.id,
          description,
          target: '_self',
        })
      })
      setEmployeeResults(employeeRows)
    },
    [fuseEmployees]
  )

  const { articlesLimit } = props

  const loadSearchData = useCallback(
    (term: string) => {
      const matches = fuseSearchData.search(term, { limit: 3 })
      const searchRows: SearchDataRow[] = []
      matches.map((e) => {
        let description = '-'
        if (e.item.title && e.item.description && e.item.searchTerms) {
          description = `${t(e.item.description)}`
        }
        searchRows.push({
          title: e.item.title,
          description,
          searchTerms: e.item.searchTerms,
          url: e.item.url,
        })
      })
      const globalSearchRows: GlobalSearchRow[] = searchRows.map((row, index) => ({
        id: `${row.url}-${index}`,
        title: t(row.title),
        description: row.description,
        url: row.url,
        target: '_self',
      }))

      setSearchDataResults(globalSearchRows)
    },
    [fuseSearchData]
  )
  //loading search sites
  const loadSearchSites = useCallback(
    (term: string) => {
      const matches = fuseSearchSites.search(term, { limit: 3 })
      const searchRows: SearchSitesRow[] = []
      matches.map((e) => {
        let description = '-'
        if (e.item.title && e.item.description && e.item.searchTerms) {
          description = `${t(e.item.description)}`
        }
        searchRows.push({
          title: e.item.title,
          description,
          searchTerms: e.item.searchTerms,
          url: e.item.url,
        })
      })
      const globalSearchRows: GlobalSearchRow[] = searchRows.map((row, index) => ({
        id: `${row.url}-${index}`,
        title: t(row.title),
        description: row.description,
        url: row.url,
        target: '_self',
      }))
      setSearchSitesResults(globalSearchRows)
    },
    [fuseSearchSites]
  )

  const loadArticles = useCallback(
    (term: string) => {
      fetchIntercomArticles(term, articlesLimit)
        .then((results) => {
          if (results === undefined) {
            setArticleResults([])
            return
          }
          const articleRows: GlobalSearchRow[] = []
          results.data.map((e) => {
            articleRows.push({
              id: e.externalLink,
              title: e.title,
              description: e.description,
              url: e.externalLink,
              target: '_blank',
              onClick: () => window.open(e.externalLink, '_blank', 'noopener,noreferrer'),
            })
          })
          setArticleResults(articleRows)
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setError(e)
          }
        })
    },
    [articlesLimit]
  )

  useEffect(() => {
    setSelectedPosition(-1)
    setArticleResults([])
    setEmployeeResults([])
    setSearchDataResults([])
    setSearchSitesResults([])
    if (searchTerm === '') {
      return
    }
    loadEmployees(searchTerm)
    loadArticles(searchTerm)
    loadSearchData(searchTerm)
    loadSearchSites(searchTerm)
  }, [searchTerm, loadArticles, loadEmployees, loadSearchData, loadSearchSites])

  const employeeNumber = employeeResults?.length ?? 0
  const searchDataNumber = searchDataResults?.length ?? 0
  const searchSitesNumber = searchSitesResults?.length ?? 0

  //closes the search input with escape or control k

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.code === 'KeyK' && e.ctrlKey) || e.code === 'Escape') {
        e.preventDefault()
        props.removeModal(props.id)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [props])

  return (
    <div
      className="global-search"
      onClick={() => {
        props.removeModal(props.id)
      }}
    >
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Input
          forwardRef={inputRef}
          autoFocus={true}
          value={inputSearchTerm}
          onChange={(e) => {
            const inputValue = e.currentTarget.value
            if (inputValue.length > characterLimit) {
              setInputSearchTerm(inputValue.substring(0, characterLimit))
            } else {
              setInputSearchTerm(inputValue)
            }
          }}
          onKeyUp={eventHandler}
          placeholder={t('header.global_search')}
        />
      </Row>
      <Row>
        <GlobalSearchCard
          title={t('global_search.employees')}
          rows={employeeResults}
          offset={0}
          position={selectedPosition}
        />
        <GlobalSearchCard
          title={t('global_search.advanced_settings')}
          rows={searchDataResults}
          offset={employeeNumber}
          position={selectedPosition}
        />
        <GlobalSearchCard
          title={t('global_search.sites')}
          rows={searchSitesResults}
          offset={employeeNumber + searchDataNumber}
          position={selectedPosition}
        />
        <GlobalSearchCard
          title={t('global_search.articles')}
          rows={articleResults}
          offset={employeeNumber + searchDataNumber + searchSitesNumber}
          position={selectedPosition}
        />
      </Row>
    </div>
  )
}
