import React, { ReactElement } from 'react'

import PayRoll, { PayRollTask } from '../../model/payRoll'
import { formatShortDate, formatShortDateTime, trimCurrentYear } from '../../utils/date-utils'
import { formatPayRollTaskType } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import Timeline from '../elements/timeline'

import './PayRollsSingle.css'

type Props = {
  payRoll: PayRoll
}

export default function TimelineCard(props: Props): ReactElement | null {
  const getTimelineItemStatus = (payRollTask: PayRollTask) => {
    switch (payRollTask.status) {
      case 'Success':
        return 'success'
      case 'Cancelled':
      case 'Failed':
        return 'error'
      case 'PartialFailed':
        return 'warning'
      default:
        return 'awaiting'
    }
  }

  const getTimelineStatus = (payRollTask: PayRollTask) => {
    let status = ''
    switch (payRollTask.status) {
      case 'Success': {
        switch (payRollTask.type) {
          case 'EIndkomst':
            status = t('pay_roll.single.timeline.status.success.e_indkomst')
            break
          case 'EIndkomstReply':
            status = t('pay_roll.single.timeline.status.success.e_indkomst_reply')
            break
          default:
            status = t('pay_roll.single.timeline.status.success')
            break
        }
        if (payRollTask.executedAt) {
          status = t('pay_roll.single.timeline.status.success_format', {
            status,
            date: trimCurrentYear(formatShortDate(payRollTask.executedAt)),
          })
        }
        break
      }
      case 'Cancelled':
        status = t('pay_roll.single.timeline.status.cancelled')
        break
      case 'Failed':
        status = t('pay_roll.single.timeline.status.failed')
        break
      case 'PartialFailed':
        status = t('pay_roll.single.timeline.status.partial_failed')
        break
      default:
        switch (payRollTask.approvalStatus) {
          case 'Pending':
            status = payRollTask.approvalDeadline
              ? t('pay_roll.single.timeline.status.pending_with_deadline', {
                  time: trimCurrentYear(formatShortDateTime(payRollTask.approvalDeadline)),
                })
              : t('pay_roll.single.timeline.status.pending')
            break
          case 'AwaitingExecution':
            status = t('pay_roll.single.timeline.status.awaiting_execution', {
              date: trimCurrentYear(formatShortDate(payRollTask.startsAt)),
            })
            break
          case 'Approved':
            status = t('pay_roll.single.timeline.status.approved', {
              date: trimCurrentYear(formatShortDate(payRollTask.startsAt)),
            })
            break
          case 'Rejected':
            status = t('pay_roll.single.timeline.status.rejected')
            break
          default:
            switch (payRollTask.type) {
              case 'EIndkomstReply':
              case 'NetsReply':
                status = t('pay_roll.single.timeline.status.expected', {
                  date: trimCurrentYear(formatShortDate(payRollTask.startsAt)),
                })
                break
              default:
                status = t('pay_roll.single.timeline.status.default', {
                  date: trimCurrentYear(formatShortDate(payRollTask.startsAt)),
                })
                break
            }
            break
        }
        break
    }
    return status
  }

  const payRoll = props.payRoll

  return (
    <Card className="pay-roll-timeline">
      <Subtitle>{t('pay_roll.single.timeline.title')}</Subtitle>
      <Timeline>
        {payRoll.tasks.map((payRollTask) => {
          return (
            <Timeline.Item key={payRollTask.id} status={getTimelineItemStatus(payRollTask)}>
              {formatPayRollTaskType(payRollTask.type)}
              <span>{getTimelineStatus(payRollTask)}</span>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </Card>
  )
}
