import { PayslipImportProvider } from '../../context/PayslipImportProvider'
import PayslipImport from './PayslipImport'

//This only exists because I needed to provide context for the payslip import component, maybe there is a smarter way to achieve this?

const PayslipImportWrapper = () => {
  return (
    <PayslipImportProvider>
      <PayslipImport />
    </PayslipImportProvider>
  )
}

export default PayslipImportWrapper
