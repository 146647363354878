import { getAccessToken } from '../../utils/cookie-utils'
import { url } from '../../utils/request-utils'
import { t, tx } from '../../utils/translation-utils'
import UploadDragger from '../antd/upload/Dragger'
import Blocktitle from '../elements/Blocktitle'
import Button from '../elements/button/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Radio from '../elements/radio'
import Spin from '../elements/spin'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import Tooltip from '../elements/tooltip'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import CreateEmployeeError from './CreateEmployeeError'
import usePayslipImport from './custom-hooks/usePayslipImport'
import ImportedEmployeesTable from './ImportedEmployeesTable'

import './payslipImport.css'

const PayslipImport = () => {
  const {
    fileName,
    step,
    setStep,
    loading,
    setSelectedPayslipOption,
    selectedPayslipOption,
    createEmployeesClicked,
    handleFileUpload,
    handleGoBack,
    employeeImportIds,
    errors,
    timeoutMessage,
    isStaged,
    handleStaging,
    goToEmployees,
  } = usePayslipImport()

  return (
    <div className="staged-import">
      <Row>
        <Col span={16}>
          <div className="staged-import-container">
            <div>
              {step === 0 && (
                <Card>
                  <div className="title-and-back-btn">
                    <Blocktitle>{t('payslip_import.title')}</Blocktitle>
                    <Button onClick={() => jsBrowserHistory.goBack()}>{t('general.back.button')}</Button>
                  </div>

                  <p>{t('payslip_import.step.1.intro.line_1')}</p>
                  <p>
                    {tx('payslip_import.step.1.intro.line_2', {
                      title: <strong>{t('payslip_import.step.1.intro.line_2.title')}</strong>,
                    })}
                  </p>

                  <p>{t('payslip_import.step.1.intro.line_3')}</p>
                  <Radio.Group
                    value={selectedPayslipOption || 'DanlonPayslips'}
                    onChange={(event) => setSelectedPayslipOption(event.target.value)}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div>
                      <Radio value="DanlonPayslips">{t('payslip_import.step.1.import_type_select.type.danloen')}</Radio>
                      <Radio value="DataloenPaySlips">
                        {t('payslip_import.step.1.import_type_select.type.dataloen')}
                      </Radio>
                      {/* <Radio disabled value="ZenegyPayslips">
                        {t('payslip_import.step.1.import_type_select.type.zenegy')}
                      </Radio> */}
                      <Radio value="ProlonPayslips">{t('payslip_import.step.1.import_type_select.type.prolon')}</Radio>
                      <Radio value="VismaPayslips">{t('payslip_import.step.1.import_type_select.type.visma')}</Radio>
                    </div>
                    <div>
                      {/* <Radio disabled value="LetlonPayslips">
                        {t('payslip_import.step.1.import_type_select.type.letlon')}
                      </Radio>
                      <Radio disabled value="LessorPayslips">
                        {t('payslip_import.step.1.import_type_select.type.lessor')}
                      </Radio>
                      <Radio disabled value="IntectPayslips">
                        {t('payslip_import.step.1.import_type_select.type.intect')}
                      </Radio>
                      <Radio disabled value="EkspresLonPayslips">
                        {t('payslip_import.step.1.import_type_select.type.ekspreslon')}
                      </Radio> */}
                    </div>
                  </Radio.Group>
                  <div className="button-row">
                    <Button type="primary" size="extra-extra-large" onClick={() => setStep(1)}>
                      {t('payslip_import.step.1.continue.upload')}
                    </Button>
                  </div>
                </Card>
              )}
              {step === 1 && (
                <Card>
                  <Blocktitle>{t('payslip_import.step.2.title')}</Blocktitle>
                  <p>{t('payslip_import.step.2.intro.line_1')}</p>
                  <p>
                    {tx('payslip_import.step.2.intro.line_2', {
                      title: <strong>{t('payslip_import.step.2.intro.line_2.title')}</strong>,
                    })}
                  </p>
                  {fileName && !errors.hasError && !loading && !isStaged && (
                    <>
                      <p>{t('payslip_import.step.2.button.upload.success')}</p>
                      <p style={{ color: 'green', fontWeight: 'bold' }}>{fileName}</p>
                    </>
                  )}
                  {fileName && !errors.hasError && !loading && isStaged && (
                    <>
                      <p>{t('payslip_import.step.2.button.staging.success')}</p>
                      <p style={{ color: 'green', fontWeight: 'bold' }}>{fileName}</p>
                    </>
                  )}

                  {errors.hasError && !loading && (
                    <>
                      <p style={{ color: 'red' }}>{t(errors.message)}</p>
                    </>
                  )}
                  <div className="button-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={() => handleGoBack(0)}
                      size="extra-extra-large"
                      className="gtm-stagedfile-back-2 back-button"
                      disabled={loading ? true : false}
                    >
                      {t('staged_import.step.2.button.back')}
                    </Button>
                    {!errors.hasError && (
                      <UploadDragger
                        disabled={loading ? true : false}
                        showUploadList={false}
                        name={'fileData'}
                        action={url('v2/stagedFiles')}
                        headers={{
                          authorization: getAccessToken(),
                        }}
                        onChange={handleFileUpload}
                        accept="application/pdf"
                      >
                        <Button
                          size="extra-extra-large"
                          type="secondary"
                          className="gtm-stagedfile-upload"
                          prefixIcon="arrowUpInsideCloud"
                          disabled={loading ? true : false}
                        >
                          {!errors.hasError && !loading && fileName
                            ? t('payslip_import.step.2.button.upload.another')
                            : t('payslip_import.step.2.button.upload')}
                        </Button>
                      </UploadDragger>
                    )}
                    {isStaged && !errors.hasError && !loading && (
                      <Button
                        size="extra-extra-large"
                        type="primary"
                        className="gtm-stagedfile-upload"
                        onClick={() => setStep(2)}
                      >
                        {t('payslip_import.step.2.button.proceed')}
                      </Button>
                    )}
                    {fileName && !errors.hasError && !isStaged && (
                      <Button
                        type="secondary"
                        disabled={loading ? true : false}
                        onClick={handleStaging}
                        size="extra-extra-large"
                      >
                        {t('payslip_import.step.2.button.process')}
                      </Button>
                    )}
                  </div>
                </Card>
              )}
              {step === 2 && (
                <Card>
                  <Blocktitle>{t('payslip_import.step.3.title')}</Blocktitle>

                  <div>
                    <p>{t('payslip_import.step.3.intro.line_1')}</p>
                    <p>{t('payslip_import.step.3.intro.line_2')}</p>
                    <p>{t('payslip_import.step.3.intro.line_3')}</p>
                  </div>

                  <div className="button-row">
                    <Button
                      disabled={!loading ? false : true}
                      size="extra-extra-large"
                      type="secondary"
                      onClick={goToEmployees}
                      className="gtm-stagedfile-done"
                    >
                      {errors.hasError
                        ? t('payslips_import.step.3.button.finish')
                        : t('payslips_import.step.3.button.cancel')}
                    </Button>
                    <Tooltip
                      title={employeeImportIds.length ? '' : t('payslip_import.step.3.tooltip.not.approved')}
                      placement="bottomRight"
                    >
                      <span>
                        <Button
                          size="extra-extra-large"
                          prefixIcon="plusCircle"
                          type="primary"
                          onClick={createEmployeesClicked}
                          className="gtm-stagedfile-done"
                          disabled={employeeImportIds.length && !loading && !errors.hasError ? false : true}
                        >
                          {t('payslips_import.step.3.button.done')}
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </Col>
        <Col span={7}>
          <Card>
            <Subtitle>{t('payslip_import.steps.title')}</Subtitle>
            <p>{t('payslip_import.steps.intro')}</p>

            <Steps direction="vertical" current={step}>
              <Steps.Step title={t('payslip_import.steps.1.summary')} />
              <Steps.Step title={t('payslip_import.steps.2.summary')} />
              <Steps.Step title={t('payslip_import.steps.3.summary')} />
            </Steps>
          </Card>
        </Col>
      </Row>
      {loading && (
        <div style={{ textAlign: 'center', marginBottom: '2vh' }}>
          <Spin tip={timeoutMessage} />
        </div>
      )}

      {step === 2 && !loading && !errors.hasError && (
        <div style={{ marginTop: '2vh' }}>
          <ImportedEmployeesTable />
        </div>
      )}
      {errors.hasError && employeeImportIds.length > 0 && <CreateEmployeeError />}
    </div>
  )
}

export default PayslipImport
