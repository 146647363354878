import { usePayslipImportContext } from '../../context/PayslipImportContext'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import CustomTable from '../elements/custom-table'
import Title from '../elements/Title'
import useAdditionalInfo from './custom-hooks/useAdditionalInfo'
import EmployeeImportDetails from './EmployeeImportDetails'

const ImportedEmployeesTable = () => {
  const { findPensionName } = useAdditionalInfo()
  const { employees } = usePayslipImportContext()
  return (
    <Card>
      <Title>{t('payslip_import.employees.table.title')}</Title>
      <CustomTable columns={4}>
        <CustomTable.Header>
          <CustomTable.TH style={{ width: '25%' }}>{t('payslip_import.employees.table.header.name')}</CustomTable.TH>
          <CustomTable.TH style={{ width: '25%' }}>
            {t('payslip_import.employees.table.header.national.id')}
          </CustomTable.TH>
          <CustomTable.TH style={{ width: '25%' }}>
            {t('payslip_import.employees.table.header.approved')}
          </CustomTable.TH>
          <CustomTable.TH style={{ width: '25%' }}>
            {t('payslip_import.employees.table.header.show.info')}
          </CustomTable.TH>
        </CustomTable.Header>
        <CustomTable.Body>
          {employees.map((employee, index) => (
            <EmployeeImportDetails
              indexNumber={(index + 1).toString()}
              key={employee.importID}
              employeeData={employee}
              findPensionName={findPensionName}
            />
          ))}
        </CustomTable.Body>
      </CustomTable>
    </Card>
  )
}

export default ImportedEmployeesTable
