import React from 'react'

import { usePayslipImportContext } from '../../context/PayslipImportContext'

const CreateEmployeeError = () => {
  const { errors } = usePayslipImportContext()

  const { backgroundColor = '#ffe6e6', color = '#d32f2f' } = errors

  return (
    <div
      style={{
        backgroundColor,
        color,
        padding: '1rem',
        borderRadius: '8px',
        border: `1px solid ${color}`,
        maxWidth: '60%',
        justifySelf: 'center',
        marginTop: '1vh',
      }}
    >
      <div style={{ marginBottom: '0.5rem' }}>{errors.message}</div>
      <ul style={{ paddingLeft: '1rem', margin: 0 }}>
        {errors.failedItems?.map((item) => (
          <li key={item} style={{ fontWeight: 'bold', listStyleType: 'disc' }}>
            {item.replace(/^Name:\s*/, '')}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CreateEmployeeError
