import { useEffect, useState } from 'react'

import { fetchPensionCompanies } from '../../../api/pension-companies'
import PensionCompany from '../../../model/pensionCompany'
import { StagedImportDataEmployee } from '../../../model/stagedImport'

const useAdditionalInfo = () => {
  const [pensionCompanies, setPensionCompanies] = useState<PensionCompany[]>([])

  const retrievePensionCompanies = async () => {
    const returnValues = await fetchPensionCompanies()
    setPensionCompanies(returnValues.data)
  }

  useEffect(() => {
    retrievePensionCompanies()
  }, [])

  const findPensionName = (employee: StagedImportDataEmployee | null) => {
    const pensionArr = employee?.remuneration?.pension
    const pensionNamesArr: (string | undefined)[] = []
    if (!pensionArr) return undefined

    if (pensionArr.length > 1) {
      pensionArr.forEach((item) =>
        pensionNamesArr.push(pensionCompanies.find((company) => company.id === item.pensionCompanyID)?.name)
      )

      return pensionNamesArr.join(', ')
    }

    return pensionCompanies.find((company) => company.id === pensionArr[0].pensionCompanyID)?.name
  }

  return { pensionCompanies, findPensionName }
}

export default useAdditionalInfo
