import { browserHistory } from 'react-router'

import {
  ApplyStagedPayslipImportsResponse,
  StagedImport,
  StagedPayslipImportResponse,
} from '../../../api/payslip-import'
import paths from '../../../constants/paths'
import { usePayslipImportContext } from '../../../context/PayslipImportContext'
import { RequestResponse } from '../../../utils/request-utils'
import { t } from '../../../utils/translation-utils'
import { FileChangeEvent, HandleFileEvents } from '../../../utils/upload-utils'

export interface FileChangeEventExtended extends FileChangeEvent {
  fileList: File[]
}

const useUtils = () => {
  const {
    stagedImportId,
    timeoutIdRef,
    setLoading,
    setTimeoutMessage,
    setEmployees,
    setStagedImportId,
    setErrors,
    setFileName,
    setIsStaged,
    setFileId,
    setStep,
    employeeImportIds,
    setEmployeeImportIds,
  } = usePayslipImportContext()

  const toggleEmployeeImportIds = (id: string, checked: boolean) => {
    setEmployeeImportIds((prev: string[]) => (checked ? [...prev, id] : prev.filter((importId) => importId !== id)))
  }

  const resetState = () => {
    setEmployees([])
    setStagedImportId(undefined)
    setErrors({ hasError: false, message: '' })
    setFileName('')
    setIsStaged(false)
    setFileId('')
  }

  const extractStagedData = (response: RequestResponse<StagedPayslipImportResponse>) => {
    const stagedImportId = response?.data?.stagedImportID
    const companiesExtractedData = response?.data?.stagedImport?.companies || []
    const employeesExtracted = companiesExtractedData[0]?.employees || []
    const stagingDone = response?.data?.done || false
    const errorsExtracted = response?.data?.stagedImport?.errors || []
    const allEmployeesHaveErrors = employeesExtracted.every((employee) => employee.errors?.length > 0)

    return {
      stagedImportId,
      companiesExtractedData,
      employeesExtracted,
      stagingDone,
      errorsExtracted,
      allEmployeesHaveErrors,
    }
  }

  const extractAsyncStagedData = (response: RequestResponse<StagedImport>) => {
    const companiesExtracted = response?.data?.companies || []
    const employeesExtracted = companiesExtracted[0]?.employees || []
    const errorsExtracted = response?.data?.errors || []
    const allEmployeesHaveErrors = employeesExtracted.every((employee) => employee.errors?.length > 0)

    return { stagedImportId, employeesExtracted, errorsExtracted, allEmployeesHaveErrors }
  }

  const handleLoading = async () => {
    if (timeoutIdRef.current) {
      return
    }

    setLoading(true)
    setTimeoutMessage('')

    timeoutIdRef.current = setTimeout(() => {
      setTimeoutMessage(t('payslip_import.loading.timeout.text'))
      timeoutIdRef.current = setTimeout(() => {
        timeoutIdRef.current = null
      }, 3000)
    }, 5000)
  }

  const handleFileUpload = async (event: FileChangeEventExtended) => {
    HandleFileEvents(event, {
      onUploading: () => {
        handleLoading()
        resetState()
      },
      onDone: (data) => {
        if (data) {
          setFileId(data.id)
          setFileName(event.fileList[event.fileList.length - 1].name)
        }
      },
      onError: (error) => {
        setErrors({ hasError: true, message: 'payslip_import.step.2.button.staging.error' })
        console.log('error on handle file upload: ', error)
      },
      onFinally: () => {
        setLoading(false)
        setTimeoutMessage('')
      },
    })
  }

  const handleGoBack = (step: number) => {
    setStep(step)
    resetState()
  }

  const goToEmployees = () => {
    resetState()
    browserHistory.push(paths.EMPLOYEES)
  }

  const handleCreationErrors = (creationResponse: RequestResponse<ApplyStagedPayslipImportsResponse>) => {
    //super intense variable name
    const failedCreations: string[] = []

    //case: creation sent to async job
    //sets UI to green instead of the default red, since it's not actually an error
    if (!creationResponse?.data?.done) {
      setErrors({
        hasError: true,
        message: t('payslip_import.step.3.creations.async'),
        backgroundColor: '#e8f5e9',
        color: '#388e3c',
      })
      return true
    }

    creationResponse?.data.result[0].messages.forEach((employee) => {
      if (employee.state === 'Failure') {
        failedCreations.push(employee.details[0])
      }
    })

    //case: all employee creations have errors
    if (failedCreations.length == employeeImportIds.length) {
      setErrors({
        hasError: true,
        message: `${t('payslip_import.step.3.all.creations.failed')}`,
        failedItems: [
          t('payslip_import.step.3.all.creations.failed.reason.nemkonto'),
          t('payslip_import.step.3.all.creations.failed.reason.exists'),
        ],
      })
      return true
    }

    //case: only some employee creations have errors
    if (failedCreations.length > 0) {
      setErrors({
        hasError: true,
        message: `${t('payslip_import.step.3.some.creations.failed')}`,
        failedItems: failedCreations,
      })
      return true
    }
  }

  return {
    toggleEmployeeImportIds,
    goToEmployees,
    handleLoading,
    handleGoBack,
    extractAsyncStagedData,
    handleFileUpload,
    extractStagedData,
    handleCreationErrors,
  }
}

export default useUtils
