const paths = {
  JUMP: 'jump',

  PAYROLL_APPROVE_PHONE_SITE: 'a',
  DOCUMENT_SIGNING_PHONE_SITE: 'signing',
  ACCOUNT: 'account',
  CHANGE_EMAIL: 'change-email',
  VERIFY_EMAIL: 'verify-email',
  PERSON_VERIFICATION: 'verify-person',
  USER_VERIFICATION: 'verify-user',

  EMPLOYEES: 'employees',
  CONTRACTS: 'contracts',
  COMPANIES: 'companies',
  COMPANY_GROUPS: 'company-groups',
  ACCOUNTING: 'accounting',
  CAR_ALLOWANCE: 'car-allowance',
  DASHBOARD: 'dashboard',
  FREELANCERS: 'freelancers',
  FREELANCERS_OVERVIEW: 'freelancers-overview',
  INTEGRATIONS: 'integrations',
  APPROVE_TAB: 'approve',
  LEAVE_REGISTRATION: 'leave-registration',
  NOTIFICATIONS: 'notifications',
  ONE_TIME_PAYS: 'one-time-pays',
  PAY_ROLLS: 'pay',
  PDF_PREVIEW: 'pdf',
  REIMBURSEMENT_VOUCHERS: 'reimbursement-vouchers',
  STAGED_IMPORT: 'staged-import',
  TIME_REGISTRATION: 'time-registration',
  DATA_PROVIDER: 'data-provider',
  SALARY_REGISTRATION: 'salary-registration',
  SWIPE_OVERVIEW: 'swipe-overview',
  USERS: 'users',
  EMPLOYEE_TEMPLATES: 'employee-templates',
  ASSETS: 'assets',
  ASSET_CATEGORIES: 'asset-categories',
  DOCUMENTS: 'documents',
  PROJECTS: 'projects',
  WORK_HOURS: 'work-hours',
  GLOBAL_SEARCH: 'global-search',
  PAYSLIP_IMPORTS: 'payslip-imports',
  PREMIUM_DEAL: 'premium-deal',

  // Integrations
  ACCOUNTING_VOUCHERS: 'accounting-vouchers',
  ACCOUNTING_DIMENSIONS: 'accounting-dimensions',
  ACCOUNTING_DAYBOOKS: 'accounting-daybooks',
  ACCOUNTING_ORGANIZATIONS: 'accounting-organizations',
  SETTINGS: 'settings',
  ACTIVE_INFORMATION: 'active-information',
  CONTRACT_BOOK: 'contract-book',
  WEB_AUTH: 'webauth',
  WEB_CONNECT: 'webconnect',
  USER_CONNECT: 'userconnect',
  CRIIPTO: 'criipto',
  SLACK: 'slack',

  LOGIN: 'login',
  REGISTER: 'register',
  REQUEST_PASSWORD: 'request-password',
  RESET_PASSWORD: 'reset-password',
  ACCEPT_USER_INVITE: 'user-invite',
  SSO: 'sso',

  IN: 'in',

  // Redirects
  API_KEYS: 'api-keys',

  ADD: 'add',
  INVITE: 'invite',
  BATCH: 'batch',
  AWAITING_CONTRACT: 'awaiting-contract',

  TERMS: 'terms',
}
export default paths
