import { addMonths, max, startOfMonth, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../../../model/coarseCarAllowance'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { CoarseCarAllowanceReducer } from '../../../reducers/coarseCarAllowances'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, formatDate, getDate, isSameOrAfter, isSameOrBefore } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { formatDisplayNumber, formatOrdinalSuffix } from '../../../utils/number-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t, tx } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Alert from '../../elements/alert'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import HelpModal from '../../elements/HelpModal'
import Select from '../../elements/select'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CoarseCarAllowanceForm, { CoarseCarAllowanceResult } from './CoarseCarAllowanceForm'

type Props = {
  preventPeriodChange: boolean
  employee: Employee
  coarseCarAllowances: CoarseCarAllowanceReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle: SalaryCycle

  addAlert: addAlertSignature
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (carAllowance: CoarseCarAllowance) => void
}

export default function CoarseCarAllowanceTab(props: Props) {
  const [salaryPeriodID, setSalaryPeriodID] = useState(
    () => getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)?.id
  )
  const [coarseCarAllowanceID, setCoarseCarAllowanceID] = useState<string | undefined>(() => {
    const coarseCarAllowances = props.coarseCarAllowances.coarseCarAllowances.filter(
      (ca) => ca.employeeID === props.employee.id && ca.salaryPeriodID === salaryPeriodID
    )
    return coarseCarAllowances.first()?.id
  })
  const [error, setError] = useState<Error | null>(null)

  const { coarseCarAllowances, addAlert, employee } = props
  const previousCoarseCarAllowances = usePrevious(coarseCarAllowances)

  useEffect(() => {
    // Check for save callback
    if (previousCoarseCarAllowances && previousCoarseCarAllowances.saving && !coarseCarAllowances.saving) {
      // Check for no error occurred
      if (!coarseCarAllowances.error) {
        addAlert('success', t('car_allowances_tab.coarse.alert.success', { name: employee.name }), { timeout: 5 })
      }
    }
  })

  useEffect(() => {
    regularComponentDidUpdate(coarseCarAllowances.error, error, setError)
  }, [coarseCarAllowances, error])

  const getSalaryPeriods = () => {
    const contract = props.employee.activeContract
    if (!contract) {
      return []
    }
    let useFirst = false
    const salaryCycles = props.salaryCycles.filter(
      (item) => item.id === contract.salaryCycleID && item.frequency === 'Monthly'
    )
    if (salaryCycles.size > 0) {
      useFirst = true
    }
    return props.salaryCycle.salaryPeriods
      .map((salaryPeriod) => ({
        ...salaryPeriod,
        first: formatAPIDate(useFirst ? startOfMonth(getDate(salaryPeriod.end)) : salaryPeriod.start),
      }))
      .filter((salaryPeriod) => {
        const date = max([getDate(salaryPeriod.dispositionDate), getDate(salaryPeriod.end)])
        return isSameOrAfter(date, subYears(getDate(), 1)) && isSameOrBefore(date, addMonths(getDate(), 1))
      })
      .sort((a, b) => b.start.localeCompare(a.start))
  }

  const getPeriod = () => props.salaryCycle.salaryPeriods.find((period) => period.id === salaryPeriodID)

  const { salaryCycle } = props

  const getCoarseCarAllowances = useCallback(() => {
    const period = salaryCycle.salaryPeriods.find((period) => period.id === salaryPeriodID)
    if (!period) {
      return []
    }
    return coarseCarAllowances.coarseCarAllowances
      .filter((carAllowance) => carAllowance.employeeID === employee.id && carAllowance.salaryPeriodID === period.id)
      .toArray()
  }, [coarseCarAllowances, employee, salaryCycle, salaryPeriodID])

  const previousSalaryPeriodID = usePrevious(salaryPeriodID)

  useEffect(() => {
    if (previousSalaryPeriodID && previousSalaryPeriodID !== salaryPeriodID) {
      const coarseCarAllowances = getCoarseCarAllowances()
      if (coarseCarAllowances.length === 0) {
        setCoarseCarAllowanceID(undefined)
      } else {
        setCoarseCarAllowanceID(coarseCarAllowances[0].id)
      }
    }
  }, [previousSalaryPeriodID, salaryPeriodID, coarseCarAllowances, coarseCarAllowanceID, getCoarseCarAllowances])

  const getCoarseCarAllowance = () =>
    coarseCarAllowances.coarseCarAllowances.find((cca) => cca.id === coarseCarAllowanceID)

  const handleSubmit = (values: CoarseCarAllowanceResult) => {
    const period = getPeriod()
    if (!period) {
      return
    }
    const coarseCarAllowance = getCoarseCarAllowance()
    if (values.kilometers === 0) {
      if (coarseCarAllowance) {
        props.deleteCoarseCarAllowance(coarseCarAllowance)
      }
    } else {
      if (coarseCarAllowance) {
        props.updateCoarseCarAllowance({
          id: coarseCarAllowance.id,
          employeeID: props.employee.id,
          salaryPeriodID: period.id,
          kilometers: values.kilometers,
        })
      } else {
        props.createCoarseCarAllowance({
          employeeID: props.employee.id,
          salaryPeriodID: period.id,
          kilometers: values.kilometers,
        })
      }
    }
  }

  const getKilometers = () => {
    const coarseCarAllowance = getCoarseCarAllowance()
    if (coarseCarAllowance) {
      return coarseCarAllowance.kilometers
    }
    return 0
  }

  const period = getPeriod()
  if (!period) {
    return null // should not happen
  }

  const coarseCarAllowancesOnPeriod = getCoarseCarAllowances()

  return (
    <div>
      <p>
        {tx('car_allowances_tab.coarse.warning', {
          link: (
            <a
              href="https://skat.dk/erhverv/ansatte-og-loen/koerselsgodtgoerelse/dokumentation-og-kontrol-af-koerselsgodtgoerelse"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('car_allowances_tab.coarse.warning.link')}
            </a>
          ),
        })}
      </p>

      <Row>
        <Col span={12}>
          {props.preventPeriodChange ? (
            <p>
              {t('car_allowances_tab.coarse.period.no_change', {
                from: formatDate(period.start),
                to: formatDate(period.end),
              })}
            </p>
          ) : (
            <Form.Item style={{ marginBottom: 10 }}>
              <label style={{ marginBottom: 0 }}>{t('car_allowances_tab.coarse.period.select_period')}</label>
              <Select
                value={salaryPeriodID}
                dropdownMatchSelectWidth={false}
                onChange={(id) => setSalaryPeriodID(id as string)}
              >
                {getSalaryPeriods().map((salaryPeriod) => {
                  return (
                    <Select.Option key={salaryPeriod.id} value={salaryPeriod.id}>
                      {t('car_allowances_tab.coarse.period.period_range', {
                        from: formatDate(salaryPeriod.start),
                        to: formatDate(salaryPeriod.end),
                      })}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          )}
        </Col>
        {coarseCarAllowancesOnPeriod.length > 1 && (
          <Col span={12}>
            <p>
              {t('car_allowances_tab.coarse.sub_select.intro')}
              <HelpModal>
                <Subtitle>{t('car_allowances_tab.coarse.sub_select.help.title')}</Subtitle>
                <p>{t('car_allowances_tab.coarse.sub_select.help.line_1')}</p>
                <p>{t('car_allowances_tab.coarse.sub_select.help.line_2')}</p>
                <p>{t('car_allowances_tab.coarse.sub_select.help.line_3')}</p>
              </HelpModal>
            </p>
            <Select
              value={coarseCarAllowanceID}
              dropdownMatchSelectWidth={false}
              onChange={(id) => setCoarseCarAllowanceID(id as string)}
            >
              {coarseCarAllowancesOnPeriod.map((c, i) => {
                return (
                  <Select.Option key={c.id} value={c.id}>
                    {t('car_allowances_tab.coarse.sub_select.line', {
                      number: i + 1,
                      ordinal_suffix: formatOrdinalSuffix(i + 1),
                    })}{' '}
                    ({formatDisplayNumber(c.kilometers)} km)
                    {c.immutable && <> ({t('car_allowances_tab.coarse.sub_select.immutable')})</>}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
        )}
      </Row>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <CoarseCarAllowanceForm key={coarseCarAllowanceID} kilometers={getKilometers()} onSubmit={handleSubmit} />
      {props.coarseCarAllowances.saving && (
        <LoadingOverlay
          text={formatSavingText([
            { loading: props.coarseCarAllowances.saving, text: t('loading.reducer.coarse_car_allowances') },
          ])}
        />
      )}
    </div>
  )
}
