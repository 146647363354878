import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import PDFPreviewTypes from '../../constants/pdf-preview-types'
import Company, { CompanyMutableFields } from '../../model/company'
import StripeConfiguration, { StripeConfigurationSetup } from '../../model/stripeConfiguration'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { InvoiceReducer } from '../../reducers/invoices'
import { StripeConfigurationReducer } from '../../reducers/stripeConfiguration'
import { formatDate } from '../../utils/date-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import InvoiceEmailModal from './InvoiceEmailModal'
import StripeIntegrationModal from './stripe/StripeIntegrationModal'

type Props = {
  company: Company
  companies: CompanyReducer
  invoices: InvoiceReducer
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  stripeConfiguration: StripeConfigurationReducer

  addAlert: addAlertSignature
  getInvoices: (payRollID?: string, companyID?: string) => void
  getCompanyPaymentIntegrations: () => void
  getStripeConfiguration: () => void
  addStripeConfiguration: () => Promise<StripeConfigurationSetup | void>
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
  updateCompany: (company: CompanyMutableFields) => void
}

export default function InvoicesTab(props: Props): ReactElement | null {
  const [showStripe, setShowStripe] = useState(false)
  const [showInvoiceEmail, setShowInvoiceEmail] = useState(false)
  const [modalKey, setModalKey] = useState(1)

  const {
    company,
    invoices,
    getInvoices,
    companyPaymentIntegrations,
    getCompanyPaymentIntegrations,
    stripeConfiguration,
    getStripeConfiguration,
  } = props

  useEffect(() => {
    const companyID = company.id
    if (invoices.companyID !== companyID || invoices.payRollID || (!invoices.loading && !invoices.loaded)) {
      getInvoices(undefined, companyID)
    }

    if (!companyPaymentIntegrations.loading && !companyPaymentIntegrations.loaded) {
      getCompanyPaymentIntegrations()
    }
    if (!stripeConfiguration.loading && !stripeConfiguration.loaded) {
      getStripeConfiguration()
    }
  }, [
    invoices,
    company,
    companyPaymentIntegrations,
    stripeConfiguration,
    getInvoices,
    getCompanyPaymentIntegrations,
    getStripeConfiguration,
  ])

  const setStripeVisibility = (showStripe: boolean) => {
    setShowStripe(showStripe)
    setModalKey((prev) => prev + 1)
  }
  const setInvoiceEmailVisibility = (showInvoiceEmail: boolean) => {
    setShowInvoiceEmail(showInvoiceEmail)
    setModalKey((prev) => prev + 1)
  }

  const hasIntegration = () => {
    return props.companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => v.paymentIntegrationType === 'Stripe' && v.status !== 'Expired'
    )
  }
  const getStripeDescription = () => {
    const conf = props.stripeConfiguration
    if (!hasIntegration() || !conf.cardBrand) {
      return undefined
    }
    const cardBrand = `${conf.cardBrand.substring(0, 1).toUpperCase()}${conf.cardBrand.substring(1)}`
    const cardExpiry = `${(conf.expireMonth || 0) < 10 ? '0' : ''}${conf.expireMonth}/${(conf.expireYear || 0) % 100}`
    return (
      <span style={{ marginRight: 20 }}>
        {t('invoices.tab.header.active_card', {
          brand: cardBrand,
          last4: conf.last4 ?? '',
          expiry: cardExpiry,
        })}
      </span>
    )
  }

  type InvoiceRow = {
    key: string
    id: string
    invoiceDate: string
    description: string
    subTotal: string
    total: string
    dueDate: string
    status: string
    isInvoiced: boolean
  }

  const columns = [
    { title: t('invoices.tab.table.header.status'), dataIndex: 'status', key: 'status' },
    { title: t('invoices.tab.table.header.invoice_date'), dataIndex: 'invoiceDate', key: 'invoiceDate' },
    { title: t('invoices.tab.table.header.description'), dataIndex: 'description', key: 'description' },
    { title: t('invoices.tab.table.header.sub_total'), dataIndex: 'subTotal', key: 'subTotal' },
    { title: t('invoices.tab.table.header.total'), dataIndex: 'total', key: 'total' },
    { title: t('invoices.tab.table.header.due_date'), dataIndex: 'dueDate', key: 'dueDate' },
    {
      title: '',
      dataIndex: '',
      key: 'x1',
      width: 1,
      render: (invoice: InvoiceRow) =>
        invoice.isInvoiced && (
          <Link
            to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.INVOICE + '/' + invoice.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="arrowDownIntoTray" />
          </Link>
        ),
    },
  ]

  const getInvoiceRows = (): InvoiceRow[] => {
    return props.invoices.invoices
      .map((invoice) => {
        let status = ''
        let isInvoiced = false
        switch (invoice.state) {
          case 'Pending':
            status = t('invoices.tab.table.status.pending')
            break
          case 'Invoiced':
            status = t('invoices.tab.table.status.invoiced')
            isInvoiced = true
            break
          case 'Paid':
            status = t('invoices.tab.table.status.paid')
            isInvoiced = true
            break
          case 'Rejected':
            status = t('invoices.tab.table.status.rejected')
            break
          default:
            break
        }
        return {
          key: invoice.id,
          id: invoice.id,
          invoiceDate: formatDate(invoice.invoiceDate),
          description: invoice.lines.map((line) => line.text).join(', '),
          subTotal: formatCurrency(invoice.subTotal, 2),
          total: formatCurrency(invoice.total, 2),
          dueDate: invoice.isInvoiced ? formatDate(invoice.dueDate) : '-',
          status: status,
          isInvoiced: isInvoiced,
        }
      })
      .toArray()
  }

  if (!props.invoices.loaded || !props.companyPaymentIntegrations.loaded || !props.stripeConfiguration.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <div>
      {props.company.isPayrollLocked && (
        <Alert type={'warning'} message={t('invoices.tab.warnings.payroll_locked')} showIcon />
      )}
      <Card>
        <TitleMenu>
          {getStripeDescription()}
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setInvoiceEmailVisibility(true)
            }}
          >
            <Button size="extra-large" className="gtm-set-invoice-email">
              {t('invoices.tab.header.invoice_email')}
            </Button>
          </DumbLink>
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setStripeVisibility(true)
            }}
          >
            <Button size="extra-large" type="primary" className="gtm-add-stripe-integration">
              {hasIntegration()
                ? t('invoices.tab.header.integration.change')
                : t('invoices.tab.header.integration.create')}
            </Button>
          </DumbLink>
        </TitleMenu>
        <Title>{t('invoices.tab.title')}</Title>

        <Table columns={columns} dataSource={getInvoiceRows()} pagination={false} />
      </Card>

      <Modal
        key={`stripe-${modalKey}`}
        visible={showStripe}
        onOk={() => setStripeVisibility(false)}
        onCancel={() => setStripeVisibility(false)}
        width={376}
        footer={null}
      >
        <StripeIntegrationModal
          visible={showStripe}
          company={props.company}
          hasIntegration={hasIntegration()}
          closeModal={() => setStripeVisibility(false)}
          addAlert={props.addAlert}
          addStripeConfiguration={props.addStripeConfiguration}
          updateStripeConfiguration={props.updateStripeConfiguration}
        />
      </Modal>

      <Modal
        key={`invoiceEmail-${modalKey}`}
        visible={showInvoiceEmail}
        onOk={() => setInvoiceEmailVisibility(false)}
        onCancel={() => setInvoiceEmailVisibility(false)}
        width={376}
        footer={null}
      >
        <InvoiceEmailModal
          visible={showInvoiceEmail}
          company={props.company}
          companies={props.companies}
          closeModal={() => setInvoiceEmailVisibility(false)}
          addAlert={props.addAlert}
          updateCompany={props.updateCompany}
        />
      </Modal>
    </div>
  )
}
