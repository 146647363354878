import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import Company from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import CompanyPricingPackage from '../../../model/companyPricingPackage'
import { PricingPackageGroup, PricingPackageType } from '../../../model/pricingPackage'
import { CompanyPricingPackageReducer } from '../../../reducers/companyPricingPackages'
import { PricingPackageReducer } from '../../../reducers/pricingPackages'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { getDate, isTimeBefore } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { beforeNewAutomaticPrice, getPrickingPackagePrice } from '../../../utils/misc-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { getMissingPackageIDs } from '../../../utils/pricing-package-utils'
import { t, tx } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Subtitle from '../../elements/Subtitle'
import Switch from '../../elements/switch'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import PricingPackageCampaignForm, { CampaignResult } from './PricingPackageCampaignForm'

import './PricingPackage.css'

type Props = {
  company: Company
  companyFeatures: List<CompanyFeature>
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer

  addAlert: addAlertSignature
  getPricingPackages: (includePackages: string[]) => void
  addCompanyPricingPackage: (packageID: string) => Promise<CompanyPricingPackage | void>
  activateCompanyPricingPackageCode: (code: string) => void
  getCompanyPricingPackages: () => void
  deleteCompanyPricingPackage: (packageID: string) => Promise<boolean | void>
}

export default function PricingPackagePanel(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companyPricingPackages, pricingPackages, getPricingPackages, getCompanyPricingPackages } = props

  useEffect(() => {
    if (!companyPricingPackages.loading && !companyPricingPackages.loaded) {
      getCompanyPricingPackages()
    }
  })

  useEffect(() => {
    if (companyPricingPackages.loaded) {
      const missingPackages = getMissingPackageIDs(
        pricingPackages.pricingPackages.toArray(),
        companyPricingPackages.companyPricingPackages.toArray()
      )
      if (!pricingPackages.loading && (!pricingPackages.loaded || missingPackages.length > 0)) {
        getPricingPackages(missingPackages)
      }
    }
  })

  useEffect(() => {
    regularComponentDidUpdate(companyPricingPackages.error, error, setError)
  }, [companyPricingPackages, error])

  const { addAlert, company } = props
  const previousCompanyPricingPackages = usePrevious(companyPricingPackages)

  useEffect(() => {
    // Check for save callback
    if (previousCompanyPricingPackages && previousCompanyPricingPackages.saving && !companyPricingPackages.saving) {
      // Check for no error occurred
      if (!companyPricingPackages.error) {
        addAlert('success', t('pricing_package.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  })

  const activateCampaignCode = (result: CampaignResult) => {
    props.activateCompanyPricingPackageCode(result.code)
  }

  let canDowngrade = true
  if (props.company.downgradableDate !== undefined) {
    canDowngrade = isTimeBefore(getDate(props.company.downgradableDate), getDate())
  }

  type Package = {
    id?: string
    type?: PricingPackageType
    group?: PricingPackageGroup
    pricingPackageCampaign?: boolean
    name: string
    selectable?: boolean
    companyPricingPackageID?: string
  }

  const getPackages = (): Package[] => {
    return props.pricingPackages.pricingPackages
      .map((pricingPackage) => {
        const pkg: Package = {
          id: pricingPackage.id,
          type: pricingPackage.type,
          group: pricingPackage.group,
          name: pricingPackage.name,
          selectable: pricingPackage.selectable,
        }
        const companyPricingPackage = props.companyPricingPackages.companyPricingPackages.find(
          (v) => v.pricingPackageID === pricingPackage.id
        )
        if (companyPricingPackage) {
          pkg.companyPricingPackageID = companyPricingPackage.id
          pkg.pricingPackageCampaign = companyPricingPackage.pricingPackageCampaign
        }
        return pkg
      })
      .toArray()
  }
  const getBaseUpgrades = () => {
    return getPackages().filter((pkg) => pkg.type === 'Base' && pkg.group !== 'Gratis' && pkg.selectable)
  }
  const getCompanyPackages = () => {
    return getPackages().filter((pkg) => pkg.companyPricingPackageID !== undefined)
  }
  const getAddons = (pkgGroup: PricingPackageGroup): Package[] => {
    if (pkgGroup !== 'Automatic') {
      return []
    }
    const addons = [{ name: 'Swipe' }]
    return [...addons, ...getPackages().filter((pkg) => pkg.type === 'Addon')]
  }

  const getPackageDetails = (group: PricingPackageGroup) => {
    switch (group) {
      case 'Automatic': {
        return {
          id: 'pricing-package-upgrade-to-basic',
          name: 'Basic',
          title: (
            <>
              {tx('pricing_package.package.automatic.title', {
                price: (
                  <em>
                    {t('pricing_package.package.automatic.title_price', {
                      price: formatCurrency(getPrickingPackagePrice('Automatic')),
                    })}
                  </em>
                ),
              })}
              {beforeNewAutomaticPrice() && <small>{t('modals.basic_upgrade.new_price_coming')}</small>}
            </>
          ),
          subtitle: t('pricing_package.package.automatic.subtitle'),
          features: [
            {
              title: t('pricing_package.package.automatic.feature_1.title'),
              text: t('pricing_package.package.automatic.feature_1.line_1'),
            },
            {
              title: t('pricing_package.package.automatic.feature_2.title'),
              text: t('pricing_package.package.automatic.feature_2.line_1'),
            },
            {
              title: t('pricing_package.package.automatic.feature_3.title'),
              text: t('pricing_package.package.automatic.feature_3.line_1'),
            },
            {
              title: t('pricing_package.package.automatic.feature_4.title'),
              text: t('pricing_package.package.automatic.feature_4.line_1'),
            },
          ],
          readMoreUrl: t('pricing_package.package.automatic.read_more_url'),
          activeTitle: t('pricing_package.package.automatic.active.title'),
          activeSubtitle: t('pricing_package.package.automatic.active.subtitle'),
          canDowngrade: false,
        }
      }
      case 'Premium': {
        const filteredPackages = props.pricingPackages.pricingPackages.filter(
          (v) => v.type === 'Base' && v.group === 'Automatic' && v.selectable
        )
        return {
          id: 'pricing-package-upgrade-to-premium',
          name: 'Premium',
          title: (
            <>
              {tx('pricing_package.package.premium.title', {
                price: (
                  <em>
                    {t('pricing_package.package.premium.title_price', {
                      price: formatCurrency(getPrickingPackagePrice('Premium')),
                    })}
                  </em>
                ),
              })}
            </>
          ),
          subtitle: t('pricing_package.package.premium.subtitle'),
          features: [
            {
              title: t('pricing_package.package.premium.feature_1.title'),
              text: t('pricing_package.package.premium.feature_1.line_1'),
            },
            {
              title: t('pricing_package.package.premium.feature_2.title'),
              text: [t('pricing_package.package.premium.feature_2.line_1')],
            },
            {
              title: t('pricing_package.package.premium.feature_3.title'),
              text: [t('pricing_package.package.premium.feature_3.line_1')],
            },
            {
              title: t('pricing_package.package.premium.feature_4.title'),
              text: [t('pricing_package.package.premium.feature_4.line_1')],
            },
          ],
          readMoreUrl: t('pricing_package.package.premium.read_more_url'),
          activeTitle: t('pricing_package.package.premium.active.title'),
          activeSubtitle: t('pricing_package.package.premium.active.subtitle'),
          canDowngrade: true,
          downgradesTo: filteredPackages.first()?.id,
        }
      }
      default: {
        return null
      }
    }
  }
  const getAddonDetails = (name: string) => {
    switch (name) {
      case 'Reimbursement Vouchers': {
        return {
          title: t('pricing_package.package.reimbursement_voucher.title'),
          titleNote: t('pricing_package.package.reimbursement_voucher.title_note'),
          description: (
            <p>
              {t('pricing_package.package.reimbursement_voucher.intro.line_1')}
              <br />
              <a
                href={t('pricing_package.package.reimbursement_voucher.intro.read_more_url')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('pricing_package.package.reimbursement_voucher.intro.read_more')}
              </a>
            </p>
          ),
          icon: '/images/icon-file-black-512x512.png', // <a href="https://www.flaticon.com/free-icons/paperless" title="paperless icons">Paperless icons created by srip - Flaticon</a>
          price: 69,
          pricingType1: t('pricing_package.package.reimbursement_voucher.pricing.per_1'),
          pricingType2: t('pricing_package.package.reimbursement_voucher.pricing.per_2'),
        }
      }
      case 'Swipe': {
        return {
          title: t('pricing_package.package.swipe.title'),
          titleNote: t('pricing_package.package.swipe.title_note'),
          description: (
            <p>
              {t('pricing_package.package.swipe.intro.line_1')}
              <br />
              {t('pricing_package.package.swipe.intro.line_2')}
            </p>
          ),
          icon: '/images/icon-swipe-100x48.png',
          iconHeight: 48,
          iconWidth: 100,
          price: 10,
          link: '/' + paths.COMPANIES + '/' + props.company.id + '/transfers',
          linkText: t('pricing_package.package.swipe.link_text'),
          pricingType1: t('pricing_package.package.swipe.pricing.per_1'),
          pricingType2: t('pricing_package.package.swipe.pricing.per_2'),
        }
      }
      case 'Work Hours Automatic': {
        return {
          title: t('pricing_package.package.work_hours_automatic.title'),
          titleNote: t('pricing_package.package.work_hours_automatic.title_note'),
          description: (
            <p>
              {t('pricing_package.package.work_hours_automatic.intro.line_1')}
              {/*<br />
              <a
                href={t('pricing_package.package.work_hours_automatic.intro.read_more_url')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('pricing_package.package.work_hours_automatic.intro.read_more')}
              </a>*/}
            </p>
          ),
          icon: '/images/icon-calendar-black-512x512.png', // attribute: <a href="https://www.flaticon.com/free-icons/calendar" title="calendar icons">Calendar icons created by srip - Flaticon</a>
          price: 5,
          pricingType1: t('pricing_package.package.work_hours_automatic.pricing.per_1'),
          pricingType2: t('pricing_package.package.work_hours_automatic.pricing.per_2'),
        }
      }
      default: {
        return null
      }
    }
  }

  const addPricingPackage = (id: string) => {
    props.addCompanyPricingPackage(id)
  }

  const deletePricingPackage = (id: string) => {
    props.deleteCompanyPricingPackage(id)
  }

  if (!props.pricingPackages.loaded || !props.companyPricingPackages.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  const baseUpgrades = getBaseUpgrades()
  const companyPackages = getCompanyPackages()
  const hasPremium = companyPackages.some((v) => v.group === 'Premium')
  const campaignActivated = companyPackages.some((v) => v.pricingPackageCampaign)
  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {baseUpgrades.map((pkg, i) => {
        const packageID = pkg.id

        //this is sort of a hotfix, it just removes price deal on premium (should be removed 1/5/2025)
        if (packageID === '7503f08d-f010-5099-4aa0-14ce978756eb') {
          return
        }

        if (!pkg.group || !packageID) {
          return null
        }
        if (pkg.group === 'Automatic' && hasPremium) {
          return null
        }
        const details = getPackageDetails(pkg.group)
        if (!details) {
          return null
        }
        const isActive = companyPackages.some((cPkg) => cPkg.group === pkg.group)
        const addons = getAddons(pkg.group)
        return (
          <div className="package-container" id={details.id} key={'package-container-' + i}>
            {isActive && (
              <Card key={packageID} className="has-package-card">
                <Subtitle>{details.activeTitle}</Subtitle>
                <p>{details.activeSubtitle}</p>

                {details.canDowngrade && canDowngrade && (details.downgradesTo || pkg.companyPricingPackageID) ? (
                  <Button
                    onClick={() => {
                      if (details.downgradesTo) {
                        addPricingPackage(details.downgradesTo)
                      } else if (pkg.companyPricingPackageID) {
                        deletePricingPackage(pkg.companyPricingPackageID)
                      }
                    }}
                    size="extra-extra-large"
                    danger
                    id="button-downgrade"
                  >
                    {t('pricing_package.action.downgrade.button')}
                  </Button>
                ) : (
                  <a href="mailto:support@salary.dk">{t('pricing_package.action.downgrade.contact')}</a>
                )}
              </Card>
            )}
            {!isActive && (
              <Card key={packageID} className="package-base-card">
                <div className="package-base-card-header">
                  <div className="package-base-card-info">
                    <a href={details.readMoreUrl} target="_blank" rel="noopener noreferrer">
                      Læs mere
                    </a>
                    <Button
                      onClick={() => addPricingPackage(packageID)}
                      size="extra-extra-large"
                      type="primary"
                      id="button-upgrade"
                    >
                      {t('pricing_package.action.upgrade.button')}
                    </Button>
                  </div>
                  <div className="package-base-card-title">{details.title}</div>
                  <div className="package-base-card-subtitle">{details.subtitle}</div>
                </div>

                <Row>
                  {details.features.map((feature, i) => {
                    return (
                      <Col key={i} span={6}>
                        <strong>{feature.title}</strong>
                        {Array.isArray(feature.text)
                          ? feature.text.map((line, i) => {
                              return <p key={'p-' + i}>{line}</p>
                            })
                          : feature.text}
                      </Col>
                    )
                  })}
                </Row>
                {props.companyPricingPackages.saving && <LoadingOverlay />}
              </Card>
            )}

            {addons.length > 0 && (
              <div className="package-addons">
                {addons.map((pkg, i) => {
                  const details = getAddonDetails(pkg.name)
                  if (!details) {
                    return null
                  }
                  let isActive = pkg.companyPricingPackageID !== undefined
                  let isLocked = false
                  let price = details.price
                  if ((pkg.name === 'Reimbursement Vouchers' || pkg.name === 'Work Hours Automatic') && hasPremium) {
                    isActive = true
                    isLocked = true
                    price = 0
                  }
                  const iconStyle = {
                    width: details.iconWidth ? details.iconWidth + 'px' : undefined,
                    height: details.iconHeight ? details.iconHeight + 'px' : undefined,
                  }
                  return (
                    <Card className="package-addon-card" key={'addon-' + i}>
                      {!details.link && (
                        <div className="ant-switch-wrapper">
                          <Switch
                            checked={isActive}
                            disabled={isLocked}
                            onChange={(e: boolean) => {
                              if (e) {
                                addPricingPackage(pkg.id!)
                              } else if (pkg.companyPricingPackageID) {
                                deletePricingPackage(pkg.companyPricingPackageID)
                              }
                            }}
                          />
                          {props.companyPricingPackages.saving && <LoadingOverlay />}
                        </div>
                      )}
                      {details.link && (
                        <div className="ant-link-box">
                          <Link to={details.link}>{details.linkText}</Link>
                        </div>
                      )}

                      {details.icon && (
                        <img src={details.icon} alt="" className="package-addon-card-icon" style={iconStyle} />
                      )}
                      <div className={'package-addon-card-price'}>
                        <span>{formatCurrency(price)}</span>
                        <br />
                        {t('pricing_package.pricing.per', { unit: details.pricingType1 })}
                        <br />
                        {t('pricing_package.pricing.per', { unit: details.pricingType2 })}
                      </div>

                      <Subtitle>
                        {details.title}
                        {details.titleNote && <span> {details.titleNote}</span>}
                      </Subtitle>
                      {details.description}
                    </Card>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
      {!campaignActivated && <PricingPackageCampaignForm onSubmit={activateCampaignCode} />}
      {props.companyPricingPackages.saving && <LoadingOverlay />}
    </div>
  )
}
