import React from 'react'

import { StagedImportDataEmployee } from '../../model/stagedImport'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button/button'
import Checkbox from '../elements/checkbox'
import CustomTable from '../elements/custom-table'
import useEmployeeImportDetails from './custom-hooks/useEmployeeImportDetails'
import useUtils from './custom-hooks/useUtils'
import ImportDetailSection from './ImportDetailSection'
// import './styles/EmployeeImportDetails.css'

interface EmployeeImportDetailsProps {
  employeeData: StagedImportDataEmployee
  indexNumber: string
  findPensionName: (employee: StagedImportDataEmployee | null) => string | undefined
}

const EmployeeImportDetails: React.FC<EmployeeImportDetailsProps> = ({
  employeeData,
  indexNumber,
  findPensionName,
}) => {
  const { renderInputField, employee, toggleFold, openSection, noValuePresent, translate } =
    useEmployeeImportDetails(employeeData)
  const { toggleEmployeeImportIds } = useUtils()

  return (
    <>
      <CustomTable.Row>
        <CustomTable.TD
          spanRow={1}
          style={{
            borderBottom: openSection['all'] ? 'none' : '1px solid #e8e9f0',
            color: employee?.name ? 'inherit' : 'red',
          }}
        >
          {employee?.name || t('payslip_import.employees.table.header.name.error', { indexNumber })}
        </CustomTable.TD>
        <CustomTable.TD
          style={{
            borderBottom: openSection['all'] ? 'none' : '1px solid #e8e9f0',
          }}
          spanRow={1}
        >
          {employee?.nationalID || noValuePresent}
        </CustomTable.TD>
        <CustomTable.TD
          style={{
            borderBottom: openSection['all'] ? 'none' : '1px solid #e8e9f0',
          }}
          spanRow={1}
        >
          <Checkbox
            onChange={(e) => toggleEmployeeImportIds(employee?.importID || '', e.target.checked)}
            style={{ color: 'black' }}
          >
            {t('payslip_import.employees.table.row.checkbox.label')}
          </Checkbox>
        </CustomTable.TD>
        <CustomTable.TD
          style={{
            borderBottom: openSection['all'] ? 'none' : '1px solid #e8e9f0',
          }}
          spanRow={1}
        >
          <Button onClick={() => toggleFold('all')} size="default">
            {openSection['all']
              ? t('payslip_import.employees.table.row.button.hide')
              : t('payslip_import.employees.table.row.button.show')}
          </Button>
        </CustomTable.TD>
      </CustomTable.Row>

      {openSection['all'] && (
        <>
          <CustomTable.Row style={{ verticalAlign: 'top' }}>
            <CustomTable.TD style={{ borderBottom: 'none' }}>
              <ImportDetailSection title={t('payslip_import.employees.table.row.info.title.personal.info')}>
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.address'),
                  'address',
                  employee?.address || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.zip.code'),
                  'postalCode',
                  employee?.postalCode || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.city'),
                  'city',
                  employee?.city || noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
            <CustomTable.TD style={{ borderBottom: 'none' }}>
              <ImportDetailSection title={t('payslip_import.employees.table.row.info.title.salary')}>
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.salary.type'),
                  'salaryType',
                  translate(employee?.remuneration?.salary[0]?.title) || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.salary.amount'),
                  'salary',
                  employee?.remuneration?.salary[0]?.rate.toString() || noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
            <CustomTable.TD style={{ borderBottom: 'none' }}>
              <ImportDetailSection title={t('payslip_import.employees.table.row.info.title.leave')}>
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.leave.type'),
                  'leaveType',
                  translate(employee?.remuneration?.leave[0]?.type?.name) || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.leave.max'),
                  'vacationDays',
                  translate(employee?.remuneration?.leave[0]?.days?.toString()) || noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
            <CustomTable.TD style={{ borderBottom: 'none' }}>
              <ImportDetailSection title={t('payslip_import.employees.table.row.info.title.benefits')}>
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.benefits.amount'),
                  'benefits',
                  employee?.remuneration?.benefits?.length.toString() ?? noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
          </CustomTable.Row>

          <CustomTable.Row style={{ verticalAlign: 'top' }}>
            <CustomTable.TD>
              <ImportDetailSection title={t('payslip_import.employees.table.row.info.title.pension')}>
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.pension.lines'),
                  'pensionLines',
                  employee?.remuneration?.pension.length
                    ? t('payslip_import.employees.table.row.info.field.true')
                    : t('payslip_import.employees.table.row.info.field.false')
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.pension.companies'),
                  'pensionNames',
                  findPensionName(employee) || noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
            <CustomTable.TD>
              <ImportDetailSection title={t('payslip_import.employees.table.row.info.title.supplements')}>
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.supplements.amount'),
                  'supplementsAmount',
                  employee?.remuneration?.supplements.length?.toString() || noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
            <CustomTable.TD>
              <ImportDetailSection title={t('payslip_import.employees.table.row.info.title.start.balances')}>
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.aincome'),
                  'aIncome',
                  employee?.startBalances?.dkSpecific?.aIncome?.toString() || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.atax'),
                  'aTax',
                  employee?.startBalances?.dkSpecific?.aTax?.toString() || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.amincome'),
                  'aTax',
                  employee?.startBalances?.dkSpecific?.aTax?.toString() || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.amcontribution'),
                  'amTax',
                  employee?.startBalances?.dkSpecific?.amTax?.toString() || noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
            <CustomTable.TD>
              <ImportDetailSection title="&nbsp;">
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.atp.employee'),
                  'employeeATP',
                  employee?.startBalances?.dkSpecific?.employeeATP?.toString() || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.atp.employer'),
                  'employerATP',
                  employee?.startBalances?.dkSpecific?.employerATP?.toString() || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.hours.worked'),
                  'workHours',
                  employee?.startBalances?.workHours?.toString() || noValuePresent
                )}
                {renderInputField(
                  t('payslip_import.employees.table.row.info.subtitle.start.balances.great.prayer'),
                  'workHours',
                  employee?.startBalances?.dkSpecific?.greatPrayerDay?.earned?.toString() || noValuePresent
                )}
              </ImportDetailSection>
            </CustomTable.TD>
          </CustomTable.Row>
        </>
      )}
    </>
  )
}

export default EmployeeImportDetails
