import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { ContractBookDraftCreated } from '../../api/contract-book'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanyUser from '../../model/companyUser'
import Contract from '../../model/contract'
import Department from '../../model/department'
import { EmploymentStatus, OnboardingState } from '../../model/employee'
import Employment from '../../model/employment'
import SalaryCycle from '../../model/salaryCycle'
import { DateFormat } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { ContractBookDraftReducer } from '../../reducers/contractBookDrafts'
import { ContractBookTemplateReducer } from '../../reducers/contractBookTemplates'
import { EmployeeReducer } from '../../reducers/employees'
import { EmploymentReducer } from '../../reducers/employments'
import RemunerationType from '../../types/remuneration-type'
import { formatDate } from '../../utils/date-utils'
import { EmployeePayType, getAverageHours, getEmployeePayType } from '../../utils/employee-utils'
import { formatEmployeePayType } from '../../utils/format-utils'
import { formatCurrency } from '../../utils/number-utils'
import { hasDepartmentPermission, isDepartmentRestricted } from '../../utils/permissions-utils'
import { getPage, RoutePropsLocation, setPage } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import EmployeeFilter, { EmployeeFilterPresentation, FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Radio from '../elements/radio'
import Switch from '../elements/switch'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import UserImage from '../elements/UserImage'
import DeleteEmployee from '../employees-single/delete-employee/DeleteEmployee'
import TerminateEmployee from '../employees-single/terminate-employee/TerminateEmployee'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import FeatureLock from '../widgets/FeatureLock'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import ContractBookModal from './ContractBookModal'

import './Employees.css'

function formatPay(payType: EmployeePayType | undefined, amount: number) {
  if (payType === 'Hourly Paid') {
    return t('employees.table.hourly_pay_format', {
      amount: formatCurrency(amount),
    })
  }
  if (amount !== 0) {
    return formatCurrency(amount)
  }
  return ''
}

type Props = {
  location: RoutePropsLocation // from React
  alerts: AlertReducer
  departments: List<Department>
  companyFeatures: List<CompanyFeature>
  company: Company
  companyUser?: CompanyUser
  employees: EmployeeReducer
  employments: EmploymentReducer
  salaryCycles: List<SalaryCycle>
  contractBookTemplates: ContractBookTemplateReducer
  contractBookDrafts: ContractBookDraftReducer
  isDepartmentRestricted: boolean

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addContractBookDraft: (contractBookID: string, templateID: string) => Promise<ContractBookDraftCreated | void>
  getEmployments: (employeeID: string) => void
  deleteEmployee: (employeeID: string, removeOrphans: boolean) => Promise<boolean | void>
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
}

export default function Employees(props: Props): ReactElement | null {
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })
  const [remunerationTypeFilter, setRemunerationTypeFilter] = useState<RemunerationType>()
  const hasBothRemunerationTypes = useState(
    () =>
      props.employees.employees.reduce(
        (container, employee) => {
          if (container.hasBoth) {
            return container
          }
          const contract = employee.activeContract
          if (!contract) {
            return container
          }
          if (contract.remunerationType === RemunerationType.SALARIED) {
            container.hasSalaried = true
          }
          if (
            contract.remunerationType === RemunerationType.HOURLY ||
            contract.remunerationType === RemunerationType.COMMISSIONED
          ) {
            container.hasHourly = true
          }
          if (container.hasSalaried && container.hasHourly) {
            container.hasBoth = true
          }
          return container
        },
        { hasHourly: false, hasSalaried: false, hasBoth: false }
      ).hasBoth
  )
  const [showWaiting, setShowWaiting] = useState(true)
  const [showOnLeave, setShowOnLeave] = useState(true)
  const [showTerminated, setShowTerminated] = useState(false)
  const [showContractBook, setShowContractBook] = useState(false)
  const [showTerminate, setShowTerminate] = useState<string | boolean>(false)
  const [showDelete, setShowDelete] = useState<string | boolean>(false)
  const [modalKey, setModalKey] = useState(1)
  const [showPay, setShowPay] = useState(false)
  type sortOnType = 'employee' | 'pay' | 'startDate'
  type Sort = {
    sortOn?: sortOnType
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const setTerminateVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setShowTerminate(id)
  }

  const setDeleteVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setShowDelete(id)
  }

  type EmployeeRow = {
    id: string
    key: string
    name?: string
    email?: string
    department?: string
    profileImageURL?: string
    position?: string
    noContract: boolean
    onboardingState: OnboardingState
    pay?: string
    payNo: number
    employment?: string
    employeeNumber?: string
    startDate?: string | ReactElement
    startDateInternal?: DateFormat
    employmentStatus: EmploymentStatus
    activeContract?: Contract
    immutableEndDate?: DateFormat
    showPay: boolean
    hasUser: boolean
  }

  type column = {
    title: string
    dataIndex: string
    key: string
    sorter?: sortOnType
    className?: string
    render?: (e: EmployeeRow) => React.ReactNode | null
  }

  const getColumns = (
    canSeeSalaryRates: boolean,
    hasSort: boolean,
    startDateState: 'startDate' | 'noStartDate' | 'termination'
  ): column[] => {
    const columns: column[] = [
      {
        title: t('employees.table.header.employee'),
        dataIndex: '',
        key: 'xEmployee',
        sorter: hasSort ? 'employee' : undefined,
        render: (employee: EmployeeRow) => {
          return (
            <Headline>
              <UserImage
                src={employee.profileImageURL}
                name={employee.name || employee.email || '-'}
                style={employee.department ? { marginBottom: '20px' } : {}}
              />
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>{employee.name || employee.email || '-'}</Link>{' '}
              {employee.employeeNumber && <small className="inline">#{employee.employeeNumber}</small>}{' '}
              {employee.hasUser && (
                <Icon
                  type={'phone'}
                  style={{
                    display: 'inline-block',
                    marginBottom: '-4px',
                  }}
                />
              )}
              <small>{employee.position ? employee.position : '-'}</small>
              {employee.department && <small>{employee.department}</small>}
            </Headline>
          )
        },
      },
    ]
    if (canSeeSalaryRates) {
      columns.push({
        title: t('employees.table.header.gross_pay'),
        dataIndex: '',
        key: 'xPay',
        sorter: hasSort ? 'pay' : undefined,
        render: (employee: EmployeeRow) => {
          if (employee.noContract) {
            if (employee.onboardingState === 'Draft') {
              return (
                <Link
                  to={'/' + paths.EMPLOYEES + '/' + employee.id + '/profile'}
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                >
                  + {t('employees.table.fill_in_information')}
                </Link>
              )
            }
            return (
              <Link
                to={'/' + paths.EMPLOYEES + '/' + employee.id + '/contracts/add'}
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
              >
                + {t('employees.table.add_employment')}
              </Link>
            )
          }
          if (employee.showPay) {
            return employee.pay
          }
          return (
            <i
              style={{
                color: 'var(--sally-grey-faded)',
                fontWeight: 'normal',
              }}
            >
              {t('employees.table.pay_hidden')}
            </i>
          )
        },
      })
    }
    columns.push({
      title: t('employees.table.header.employment'),
      dataIndex: 'employment',
      key: 'employment',
    })
    switch (startDateState) {
      case 'startDate':
        columns.push({
          title: t('employees.table.header.start_date'),
          dataIndex: 'startDate',
          key: 'startDate',
          sorter: hasSort ? 'startDate' : undefined,
        })
        break
      default:
        break
    }
    columns.push({
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (employee) => {
        return (
          <ContextMenu placeholder={<Icon type="paperWithPencil" />}>
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>
              <Icon type="users" /> {t('employees.table.action.details')}
            </Link>
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>
              <Icon type="paperWithPencil" /> {t('employees.table.action.edit')}
            </Link>
            {employee.employmentStatus !== 'OnLeave' && employee.activeContract && (
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/give-leave'}>
                <Icon type="suitcase" /> {t('employees.table.action.give_leave')}
              </Link>
            )}
            {employee.employmentStatus !== 'Terminated' && (
              <DumbLink
                onClick={() => {
                  setTerminateVisibility(employee.id)
                }}
              >
                <Icon type="xSignCircle" /> {t('employees.table.action.terminate')}
              </DumbLink>
            )}
            {!employee.immutableEndDate && (
              <DumbLink
                onClick={() => {
                  setDeleteVisibility(employee.id)
                }}
              >
                <Icon type="xSignCircle" /> {t('employees.table.action.delete')}
              </DumbLink>
            )}
          </ContextMenu>
        )
      },
    })
    return columns
  }

  const { location } = props

  const waitingContainer = React.useRef<HTMLDivElement>(null)

  useEffectOnce(() => {
    if (waitingContainer.current) {
      if (location.pathname === '/employees/awaiting-contract') {
        window.scrollTo(0, waitingContainer.current.offsetTop)
      }
    }
  })

  const hasContractBookFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'ContractBook')
  }
  const hasEmployeeOnboardingFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Employee Onboarding')
  }

  const setContractBookVisibility = (showContractBook: boolean) => {
    setShowContractBook(showContractBook)
  }

  const toggleWaiting = () => setShowWaiting((prev) => !prev)
  const toggleOnLeave = () => setShowOnLeave((prev) => !prev)
  const toggleTerminated = () => setShowTerminated((prev) => !prev)
  const toggleShowPay = () => setShowPay((prev) => !prev)

  const handleRemunerationTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) {
      setRemunerationTypeFilter(undefined)
    }
    setRemunerationTypeFilter(value as RemunerationType)
  }

  const getEmployees = (): EmployeeRow[] => {
    const sortDate = (a: DateFormat | undefined, b: DateFormat | undefined) => {
      if (!a) {
        return 1000
      }
      if (!b) {
        return -1000
      }
      return a.localeCompare(b)
    }
    return props.employees.employees
      .filter((employee) => {
        if (employee.affiliationType === 'Freelancer') {
          return false
        }
        if (remunerationTypeFilter) {
          const contract = employee.activeContract
          if (!contract) {
            return false
          }
          switch (remunerationTypeFilter) {
            case RemunerationType.SALARIED:
              return contract.remunerationType === RemunerationType.SALARIED
            default:
              return (
                contract.remunerationType === RemunerationType.HOURLY ||
                contract.remunerationType === RemunerationType.COMMISSIONED
              )
          }
        }
        return filterEmployee(employee, filter)
      })
      .map((employee): EmployeeRow => {
        const contract = employee.activeContract
        let employment = undefined
        let employeeNumber = undefined
        let payType = undefined
        if (contract) {
          payType = getEmployeePayType(contract)
          employment = formatEmployeePayType(payType)
          if (payType === 'Salaried') {
            if (getAverageHours(contract) <= 30) {
              employment = t('employee.employment_type.part_time')
            } else {
              employment = t('employee.employment_type.full_time')
            }
          }
        }
        let departmentName = undefined
        if (employee.departmentID) {
          const department = props.departments.find((department) => department.id === employee.departmentID)
          if (department) {
            departmentName = department.name
          }
        }
        const startDateInternal = employee.activeEmployment?.startDate
        let startDate: string | ReactElement | undefined =
          employee.activeEmployment && contract ? formatDate(startDateInternal!) : undefined
        if (employee.activeEmployment) {
          employeeNumber = employee.activeEmployment.employeeNumber
          if (!!employee.activeEmployment.endDate && employee.employmentStatus === 'Employed') {
            startDate = (
              <span>
                {startDate}
                <br />
                <small>
                  {t('employees.table.termination_at', {
                    date: formatDate(employee.activeEmployment.endDate),
                  })}
                </small>
              </span>
            )
          }
        }
        return {
          key: employee.id + Math.random(),
          id: employee.id,
          profileImageURL: employee.profileImageURL,
          name: employee.name,
          email: employee.email,
          position: contract ? contract.position : undefined,
          department: departmentName,
          pay: contract ? formatPay(payType, contract.cachedSalaryAmount) : undefined,
          payNo: contract?.cachedSalaryAmount ?? 0,
          employment,
          employeeNumber,
          startDate,
          startDateInternal,
          employmentStatus: employee.employmentStatus,
          immutableEndDate: employee.immutableEndDate,
          onboardingState: employee.onboardingState,
          noContract: !contract,
          showPay,
          hasUser: employee.hasUser,
        }
      })
      .toArray()
      .sort((a, b) => {
        let i = a
        let j = b
        if (sort.sortOrder === 'descend') {
          j = a
          i = b
        }
        const forSort = (e: EmployeeRow): string => e.name || e.email || e.id
        switch (sort.sortOn) {
          case 'employee':
            if (forSort(i) === forSort(j)) {
              return sortDate(a.startDateInternal, b.startDateInternal)
            }
            return forSort(i).localeCompare(forSort(j))
          case 'startDate':
            if (i.startDateInternal === j.startDateInternal) {
              return forSort(a).localeCompare(forSort(b))
            }
            return sortDate(i.startDateInternal, j.startDateInternal)
          case 'pay':
            if (i.payNo === j.payNo) {
              if (forSort(i) === forSort(j)) {
                return sortDate(a.startDateInternal, b.startDateInternal)
              }
              return forSort(a).localeCompare(forSort(b))
            }
            return j.payNo - i.payNo
          default:
            if (forSort(a) === forSort(b)) {
              return sortDate(a.startDateInternal, b.startDateInternal)
            }
            return forSort(a).localeCompare(forSort(b))
        }
      })
  }
  const employees = getEmployees()

  const getActiveEmployees = () => {
    return employees.filter((employee) => {
      return (
        (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
        employee.onboardingState !== 'Draft' &&
        !employee.noContract
      )
    })
  }
  const getWaitingEmployees = () => {
    return employees.filter((employee) => {
      return (
        (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
        (employee.onboardingState === 'Draft' || employee.noContract)
      )
    })
  }
  const getOnLeaveEmployees = () => {
    return employees.filter((employee) => employee.employmentStatus === 'OnLeave')
  }
  const getTerminatedEmployees = () => {
    return employees.filter((employee) => employee.employmentStatus === 'Terminated')
  }

  const getDepartments = () => {
    return props.departments.filter((department) => department.active)
  }

  const tableChange = ({ sorter }: TableChange<EmployeeRow>) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'pay':
      case 'startDate':
        setSort({
          sortOn: sorter.column.sorter,
          sortOrder: sorter.order,
        })
        break
      default:
        break
    }
  }

  const activeEmployees = getActiveEmployees()
  const waitingEmployees = getWaitingEmployees()
  const onLeaveEmployees = getOnLeaveEmployees()
  const terminatedEmployees = getTerminatedEmployees()

  const activeCount = activeEmployees.length
  const waitingCount = waitingEmployees.length
  const leaveCount = onLeaveEmployees.length
  const terminatedCount = terminatedEmployees.length
  const stillLoading = props.employees.loading && props.employees.loaded

  const restrictedByDepartments = isDepartmentRestricted(props.companyUser)
  const enabledContractTemplates = props.company.settingsEnabled.some(
    (setting) => setting === 'ContractTemplatesEnabled'
  )

  const canSeeSalaryRates = !restrictedByDepartments // if department restricted, they cannot see rates
  const canHireFire = hasDepartmentPermission(props.companyUser, undefined, 'HireFire')
  const canEditObjects = hasDepartmentPermission(props.companyUser, undefined, 'EditObjects')

  const departments = getDepartments()

  return (
    <div className="employees">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu
        style={{
          minWidth: hasBothRemunerationTypes || departments.size > 0 ? '850px' : '500px',
          textAlign: 'right',
        }}
      >
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          {canHireFire && props.company.settingsEnabled.some((setting) => setting === 'EnablePayslipImporter') && (
            <Link to={'/' + paths.PAYSLIP_IMPORTS}>
              <Button type="primary" size="extra-large" prefixIcon="plusCircle">
                {t('employees.header.import_employees')}
              </Button>
            </Link>
          )}

          {enabledContractTemplates && !restrictedByDepartments && (
            <Link to={'/' + paths.EMPLOYEE_TEMPLATES + '/'}>
              <Button size="extra-large" className="gtm-contract-templates">
                {t('employees.header.employee_templates')}
              </Button>
            </Link>
          )}
          {hasContractBookFeature() && props.contractBookTemplates.templates.size > 0 && (
            <Link to={'/' + paths.INTEGRATIONS + '/' + paths.CONTRACT_BOOK}>
              <Button size="extra-large" type="primary" className="gtm-add-contract-book-employee">
                {t('employees.header.add_by_contract_book')}
              </Button>
            </Link>
          )}
          {canHireFire && (
            <FeatureLock
              hasFeature={!props.company.activeEmployeeCap || activeCount < props.company.activeEmployeeCap}
              description={t('employees.header.add_employee_lock')}
            >
              <Link to={'/' + paths.EMPLOYEES + '/' + paths.ADD}>
                <Button size="extra-large" type="primary" className="gtm-add-employee" prefixIcon="plusCircle">
                  {t('employees.header.add_employee')}
                </Button>
              </Link>
            </FeatureLock>
          )}
          {canHireFire && hasEmployeeOnboardingFeature() && (
            <Link to={'/' + paths.EMPLOYEES + '/' + paths.INVITE}>
              <Button size="extra-large" className="gtm-invite-employee">
                {t('employees.header.invite_employee')}
              </Button>
            </Link>
          )}
          {canEditObjects && (
            <Link to={'/' + paths.EMPLOYEES + '/' + paths.BATCH}>
              <Button size="extra-large" className="gtm-batch-employees">
                {t('employees.header.batch')}
              </Button>
            </Link>
          )}
        </Row>
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <div
            style={{
              padding: '8px 0 0',
              display: 'inline-block',
              marginRight: '5px',
            }}
          >
            {canSeeSalaryRates && (
              <span>
                <Switch onChange={toggleShowPay} id="toggle-show-pay" />
                <span className="ant-switch-text" style={{ marginLeft: '5px' }}>
                  {t('employees.header.show_pay')}
                </span>
              </span>
            )}
            {hasBothRemunerationTypes && (
              <Radio.Group
                onChange={handleRemunerationTypeChange}
                defaultValue={false}
                className="employees-search-remuneration-types"
              >
                <Radio value={false}>{t('common.all')}</Radio>
                <Radio value={RemunerationType.SALARIED}>{formatEmployeePayType('Salaried')}</Radio>
                <Radio value={RemunerationType.HOURLY}>{formatEmployeePayType('Hourly Paid')}</Radio>
              </Radio.Group>
            )}
          </div>
          <EmployeeFilter
            departments={props.departments}
            companyUser={props.companyUser}
            onFilterChange={(filter) => setFilter(filter)}
          />
        </Row>
      </TitleMenu>
      <Title style={{ minHeight: '100px' }}>
        {t('employees.title.employee', { count: activeCount ?? 0 })}
        {stillLoading ? <i> ({t('employees.title.more_loading')})</i> : ''}
        <EmployeeFilterPresentation filter={filter} departments={props.departments} prefix={<br />} />
      </Title>
      <Table
        style={{ clear: 'right' }}
        columns={getColumns(canSeeSalaryRates, true, 'startDate')}
        dataSource={activeEmployees}
        pagination={{
          defaultCurrent: getPage(),
          defaultPageSize: 50,
          onChange: setPage,
        }}
        className="employees-table employees-table-visible"
        onRowClick={(employee: EmployeeRow) => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id)
        }}
        onChange={tableChange}
      />

      {waitingCount > 0 && (
        <div ref={waitingContainer}>
          <TitleMenu>
            <span
              onClick={toggleWaiting}
              className={'employees-toggle' + (showWaiting ? ' employees-toggle-visible' : '')}
            >
              {showWaiting ? t('employees.hide') : t('employees.show')}
            </span>
          </TitleMenu>
          <Title>{t('employees.awaiting.title')}</Title>
          <Table
            columns={getColumns(canSeeSalaryRates, false, 'noStartDate')}
            dataSource={waitingEmployees}
            pagination={waitingCount > 50 ? { defaultPageSize: 50 } : false}
            className={'employees-table' + (showWaiting ? ' employees-table-visible' : '')}
            onRowClick={(employee: EmployeeRow) => {
              jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id)
            }}
          />
        </div>
      )}

      {leaveCount > 0 && (
        <div>
          <TitleMenu>
            <span
              onClick={toggleOnLeave}
              className={'employees-toggle' + (showOnLeave ? ' employees-toggle-visible' : '')}
            >
              {showOnLeave ? t('employees.hide') : t('employees.show')}
            </span>
          </TitleMenu>
          <Title>{t('employees.on_leave.title')}</Title>
          <Table
            columns={getColumns(canSeeSalaryRates, false, 'startDate')}
            dataSource={onLeaveEmployees}
            pagination={leaveCount > 50 ? { defaultPageSize: 50 } : false}
            className={'employees-table' + (showOnLeave ? ' employees-table-visible' : '')}
            onRowClick={(employee: EmployeeRow) => {
              jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id)
            }}
          />
        </div>
      )}

      {terminatedCount > 0 && (
        <div>
          <TitleMenu>
            <span
              onClick={toggleTerminated}
              className={'employees-toggle' + (showTerminated ? ' employees-toggle-visible' : '')}
            >
              {showTerminated ? t('employees.hide') : t('employees.show')}
            </span>
          </TitleMenu>
          <Title className="employees-terminated">{t('employees.terminated.title')}</Title>
          <Table
            columns={getColumns(canSeeSalaryRates, false, 'termination')}
            dataSource={terminatedEmployees}
            pagination={terminatedCount > 50 ? { defaultPageSize: 50 } : false}
            className={'employees-table' + (showTerminated ? ' employees-table-visible' : '')}
            onRowClick={(employee: EmployeeRow) => {
              jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id)
            }}
          />
        </div>
      )}

      <Modal
        visible={showContractBook}
        onOk={() => setContractBookVisibility(false)}
        onCancel={() => setContractBookVisibility(false)}
        width={582}
        footer={null}
      >
        <ContractBookModal
          visible={showContractBook}
          close={() => setContractBookVisibility(false)}
          contractBookTemplates={props.contractBookTemplates}
          contractBookDrafts={props.contractBookDrafts}
          addAlert={props.addAlert}
          addContractBookDraft={props.addContractBookDraft}
        />
      </Modal>

      <Modal
        key={`delete-${modalKey}`}
        visible={showDelete !== false}
        onOk={() => setDeleteVisibility(false)}
        onCancel={() => setDeleteVisibility(false)}
        width={572}
        footer={null}
      >
        <DeleteEmployee
          visible={showDelete !== false}
          employeeID={typeof showDelete === 'string' ? showDelete : undefined}
          employees={props.employees}
          deleteEmployee={props.deleteEmployee}
          addAlert={props.addAlert}
          closeModal={() => setDeleteVisibility(false)}
        />
      </Modal>

      <Modal
        key={`terminate-${modalKey}`}
        visible={showTerminate !== false}
        onOk={() => setTerminateVisibility(false)}
        onCancel={() => setTerminateVisibility(false)}
        width={572}
        footer={null}
      >
        <TerminateEmployee
          visible={showTerminate !== false}
          employeeID={typeof showTerminate === 'string' ? showTerminate : undefined}
          employees={props.employees}
          employments={props.employments}
          salaryCycles={props.salaryCycles}
          getEmployments={props.getEmployments}
          terminateEmployment={props.terminateEmployment}
          addAlert={props.addAlert}
          closeModal={() => setTerminateVisibility(false)}
        />
      </Modal>
    </div>
  )
}
