import React, { useRef, useState } from 'react'

import { PayslipImportErrors } from '../components/import-payslips/custom-hooks/usePayslipImport'
import { PayslipImportContext } from './PayslipImportContext'

export const PayslipImportProvider = ({ children }: { children: React.ReactNode }) => {
  const [fileName, setFileName] = useState('')
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [employeeImportIds, setEmployeeImportIds] = useState<string[]>([])
  const [selectedPayslipOption, setSelectedPayslipOption] = useState('DanlonPayslips')
  const [employees, setEmployees] = useState<any[]>([])
  const [stagedImportId, setStagedImportId] = useState<string | undefined>(undefined)
  const [errors, setErrors] = useState<PayslipImportErrors>({ hasError: false, message: '' })
  const [timeoutMessage, setTimeoutMessage] = useState('')
  const [isStaged, setIsStaged] = useState(false)
  const [fileId, setFileId] = useState('')
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  const contextValue = {
    fileName,
    loading,
    step,
    employeeImportIds,
    selectedPayslipOption,
    employees,
    stagedImportId,
    errors,
    timeoutMessage,
    isStaged,
    fileId,
    timeoutIdRef,
    setFileName,
    setLoading,
    setStep,
    setEmployeeImportIds,
    setSelectedPayslipOption,
    setEmployees,
    setStagedImportId,
    setErrors,
    setTimeoutMessage,
    setIsStaged,
    setFileId,
  }

  return <PayslipImportContext.Provider value={contextValue}>{children}</PayslipImportContext.Provider>
}
