import React, { createContext, useContext } from 'react'

import { PayslipImportErrors } from '../components/import-payslips/custom-hooks/usePayslipImport'
import { StagedImportDataEmployee } from '../model/stagedImport'

interface PayslipImportContextType {
  fileName: string
  loading: boolean
  step: number
  employeeImportIds: string[]
  selectedPayslipOption: string
  employees: StagedImportDataEmployee[]
  stagedImportId?: string
  errors: PayslipImportErrors
  timeoutMessage: string
  isStaged: boolean
  fileId: string
  timeoutIdRef: React.MutableRefObject<NodeJS.Timeout | null>

  setFileName: React.Dispatch<React.SetStateAction<string>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setStep: React.Dispatch<React.SetStateAction<number>>
  setEmployeeImportIds: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedPayslipOption: React.Dispatch<React.SetStateAction<string>>
  setEmployees: React.Dispatch<React.SetStateAction<StagedImportDataEmployee[]>>
  setStagedImportId: React.Dispatch<React.SetStateAction<string | undefined>>
  setErrors: React.Dispatch<React.SetStateAction<PayslipImportErrors>>
  setTimeoutMessage: React.Dispatch<React.SetStateAction<string>>
  setIsStaged: React.Dispatch<React.SetStateAction<boolean>>
  setFileId: React.Dispatch<React.SetStateAction<string>>
}

const defaultContextValue: PayslipImportContextType = {
  fileName: '',
  loading: false,
  step: 0,
  employeeImportIds: [],
  selectedPayslipOption: 'DanlonPayslips',
  employees: [],
  stagedImportId: undefined,
  errors: { hasError: false, message: '' },
  timeoutMessage: '',
  isStaged: false,
  fileId: '',
  timeoutIdRef: { current: null },

  setFileName: () => {
    null
  },
  setLoading: () => {
    null
  },
  setStep: () => {
    null
  },
  setEmployeeImportIds: () => {
    null
  },
  setSelectedPayslipOption: () => {
    null
  },
  setEmployees: () => {
    null
  },
  setStagedImportId: () => {
    null
  },
  setErrors: () => {
    null
  },
  setTimeoutMessage: () => {
    null
  },
  setIsStaged: () => {
    null
  },
  setFileId: () => {
    null
  },
}

export const PayslipImportContext = createContext<PayslipImportContextType>(defaultContextValue)

export const usePayslipImportContext = () => {
  return useContext(PayslipImportContext)
}
