import ApplyResult from '../model/applyResult'
import { OneTimePayType } from '../model/oneTimePay'
import StagedImportResult from '../model/stagedImportResult'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export type StagedImportType =
  | 'ExcelHours'
  | 'OneTimePays'
  | 'DanloenSalary'
  | 'DataloenSalary'
  | 'ProloenSalary'
  | 'WorkfeedSalary'

type StagedImportsResponse = {
  done: boolean
  stagedImportID: string
  stagedImport?: StagedImportResult
}

export function postStagedImports(
  importType: StagedImportType,
  companyID: string,
  importFileID: string,
  salaryPeriodID?: string,
  oneTimePayType?: OneTimePayType,
  deleteUnsettled = true
): Promise<RequestResponse<StagedImportsResponse>> {
  const body = {
    type: importType,
    companyID,
    fileID: importFileID,
    salaryPeriodID: salaryPeriodID,
    oneTimePayType: oneTimePayType,
    deleteUnsettled,
  }

  console.log('body in postStagedImports: ', body)
  return secureRequest('POST', url('/v2.1/stagedImports'), {
    body,
  })
}

export function getStagedImport(importDataID: string): Promise<RequestResponse<StagedImportResult>> {
  return secureRequest('GET', url('/v2.1/stagedImports/' + importDataID))
}

type ApplyStagedImportsResponse = {
  done: boolean
  applyResultID?: string
  result?: ApplyResult[]
}

export function applyStagedImport(importDataID: string): Promise<RequestResponse<ApplyStagedImportsResponse>> {
  return secureRequest('POST', url('/v2.1/stagedImports/' + importDataID))
}

export function getStagedImportResult(applyResultID: string): Promise<RequestResponse<ApplyResult[]>> {
  return secureRequest('GET', url('/v2.1/stagedImports/' + applyResultID + '/result'))
}
