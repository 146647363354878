import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { CompanyAccountingIntegrationType } from '../../api/company-accounting-integration-setup'
import AvailableAccountingIntegration from '../../model/availableAccountingIntegration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatAccountingIntegration } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import FeatureLock from '../widgets/FeatureLock'
import jsBrowserHistory from '../widgets/jsBrowserHistory'

type Props = {
  availableAccountingIntegrations: List<AvailableAccountingIntegration>

  integrationType?: CompanyAccountingIntegrationType
}

export type IntegrationTypeFields = {
  readonly step: 'SelectIntegration'
  integrationType?: CompanyAccountingIntegrationType
}

function IntegrationTypeForm(
  props: Props & FormComponentProps<IntegrationTypeFields, IntegrationTypeFields>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      <div className="title-and-back-btn">
        <Subtitle>{t('accounting_integration.integration_type.title')}</Subtitle>
        <Button onClick={() => jsBrowserHistory.goBack()}>{t('general.back.button')}</Button>
      </div>
      <p>{t('accounting_integration.integration_type.intro')}</p>
      {props.getFormError()}
      <Subcard className="integration-search">
        {decorateField('integrationType', {
          title: t('accounting_integration.integration_type.form.integration_type'),
          placeholder: t('accounting_integration.integration_type.form.integration_type.placeholder'),
          validate: (val) => (!val ? t('accounting_integration.integration_type.form.integration_type.required') : ''),
        })(
          <Select dropdownMatchSelectWidth={false}>
            <Select.Option value={'None'}>{formatAccountingIntegration('None')}</Select.Option>
            {props.availableAccountingIntegrations
              .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
              .map((integration) => (
                <Select.Option key={integration.type} value={integration.type}>
                  {integration.displayName}
                </Select.Option>
              ))}
          </Select>
        )}
      </Subcard>
      <Row>
        <Col span={24}>
          <FeatureLock
            featureType={'Accounting Integration'}
            description={t('accounting_integration.integration_type.next.lock')}
          >
            <Button
              htmlType="submit"
              size="extra-extra-large"
              type="primary"
              className="gtm-accounting-integration-continue-to-connection"
            >
              {t('accounting_integration.integration_type.next')}
            </Button>
          </FeatureLock>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, IntegrationTypeFields, IntegrationTypeFields>({
  mapPropsToFields: (props) => ({
    integrationType: props.integrationType,
    step: 'SelectIntegration',
  }),
  onSubmit: (values) => values,
})(IntegrationTypeForm)
